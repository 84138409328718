// imports
import React from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import SWL from '../../images/SWL.png';
import URS from '../../images/URS.png';

// styled divs saved to variables
const Section = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  backgroundColor: '#f1f1f1',
  padding: '100px 0',
  [theme.breakpoints.down('sm')]: {
    padding: '60px 0'
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 500,
  marginBottom: '1.5rem',
  color: '#5aaf5e',
  fontFamily: "Poppins, sans-serif",
  padding: '0 32px',
  [theme.breakpoints.down('md')]: {
    fontSize: '36px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '34px',
  },
  [theme.breakpoints.down('xs')]: {
   fontSize: '32px',
  },
}));

const Detail = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  padding: '0 32px 20px',
  fontWeight: "normal",
  fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',

  [theme.breakpoints.down('sm')]: {
    padding: '0 24px 20px',
   },
  [theme.breakpoints.down('xs')]: {
    padding: '0 16px 20px',
   },
}));

const SubSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  width: '80%',
  margin: ' 0 0 2.5rem',
  maxWidth: '1075px',
  [theme.breakpoints.down('md')]: {
   width: '85%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
  [theme.breakpoints.down('xs')]: {
    width: '95%',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const BoxContainer = styled(Box)({
  width: '97.5%',
  padding: '10px 16px',
});

const Image = styled('img')(({ theme }) => ({
    verticalAlign: 'middle',
    marginBottom: '2.5px',
    maxWidth: "153.75px",
    maxHeight:"153.75px",
    [theme.breakpoints.down('md')]: {
      width: '132px',
      height: '132px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '111px',
      height: '111px',
    },
}));

const SubTitle = styled(Typography)({
  fontSize: '28px',
  fontWeight: 500,
  margin: '0 0 36px',
  color: '#5aaf5e',
  fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
});

const Paragraph = styled(Typography)({
  fontSize: '16px',
  color: '#212529',
  fontWeight: 400,
  marginBottom: '1rem',
  textAlign: 'left',
  fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
});

const BulletPoint = styled(Typography)({
  fontSize: '16px',
  fontWeight: 400,
  textAlign: 'left',
  fontFamily: "Poppins, sans-serif",
  margin: '10px 0',
  color: '#515151',
});

const InfoButton = styled(Button)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 400,
  padding: '20px 30px 18px',
  background: "#5aaf5e",
  color: 'white',
  borderRadius: '20px',
  fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  '&:hover': {
    backgroundColor: "#d9d9db",
    color: "#3779CA",
  },
  [theme.breakpoints.down('sm')]: {
    padding: '16px 20px 14px',
  },
  [theme.breakpoints.down('xs')]: {
   fontSize: '14px',
  },
}));

// component content
const Treatments = () => {
  return (
    <Section id='treatments'>
      <Title variant="h2" component="h1">
      Kidney Stone Treatments
      </Title>
      <Detail variant="body1">
      SWL (Shock Wave Lithotripsy) and URS (Ureteroscopy) are the primary forms of surgical intervention for the treatment of kidney stones.
      </Detail>
      <SubSection>
        <BoxContainer>
          <Image src={SWL} alt="Image 1" />
          <SubTitle variant="h5" component="h3">
            SWL
          </SubTitle>
          <Paragraph variant="body1">
          SWL is a non-invasive treatment using focused shockwaves to break down the stone from outside the body. The stone is targeted and monitored throughout the treatment using ultrasound or fluoroscopic imaging under the observation of your Urologist. During the procedure, the shockwaves gradually break down the stone structure into fine particles that can be easily urinated out of the body.
          </Paragraph>
          <BulletPoint variant="body1">• Out-Patient Procedure</BulletPoint>
          <BulletPoint variant="body1">• High Success Rates</BulletPoint>
          <BulletPoint variant="body1">• Non-Invasive (Low Complication Rates)</BulletPoint>
          <BulletPoint variant="body1">• Normal Recovery Time of a Day or Two</BulletPoint>
        </BoxContainer>
        <BoxContainer>
          <Image src={URS} alt="Image 2" />
          <SubTitle variant="h5" component="h3">
          URS
          </SubTitle>
          <Paragraph variant="body1">
          URS is commonly used to treat stones in complex areas of the urinary tract. A longer and more invasive operation, URS allows the Urologist to directly visualize the stone utilizing a small camera. A ureteroscope is inserted into the urethra and passed through the bladder to the ureter. The treatment enables the Urologist to touch the stone surface directly with a laser fiber, which causes it to crumble. A stent is typically placed after surgery.
          </Paragraph>
          <BulletPoint variant="body1">• Out-Patient Procedure</BulletPoint>
          <BulletPoint variant="body1">• High Success Rates</BulletPoint>
          <BulletPoint variant="body1">• Invasive (Possible Complication Risk to Urinary Tract)</BulletPoint>
          <BulletPoint variant="body1">• Normal Recovery Time of 5-7 Days</BulletPoint>
        </BoxContainer>
      </SubSection>
      <InfoButton variant="contained" color="tertiary" href="#try-treatogether">
        TRY TREATOGETHER
      </InfoButton>
    </Section>
  );
}

// export
export default Treatments;
