// imports
import React, { useState, useEffect, useMemo } from 'react';
import { AppBar, Toolbar, IconButton, useMediaQuery, Menu, MenuItem, Button } from '@material-ui/core';
import { styled, useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from '../../images/TtLogoW.png';
import { Link } from 'react-scroll';

// styled divs saved to variables
const StyledAppBar = styled(AppBar)({
  background: "#5aaf5e",
  width: "100%",
  alignItems: "center",
  boxShadow: "none",
  padding: '0 1%',
});

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignSelf: "center",
  width: "98%",
  maxWidth: "1040px",
  padding: "0 32px",

  [theme.breakpoints.down('sm')]: {
    padding: '0 16px',
   },
  [theme.breakpoints.down('xs')]: {
    padding: '0 10px',
   },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginLeft: "20px",
  width: "100px",
  '&:hover': {
    backgroundColor: "transparent",
  },
  [theme.breakpoints.down('xs')]: {
    marginRight: '90px',
   },
}));

const LogoImage = styled('img')(({ theme }) => ({
  height: '50px',
  marginTop: '6px',
  position: "static",
  verticalAlign: 'middle',
  [theme.breakpoints.down('md')]: {
   height: '48px',
  },
  [theme.breakpoints.down('sm')]: {
   height: '45px',
  },
  [theme.breakpoints.down('xs')]: {
   height: '40px',
  },
}));

const NavContainer = styled('div')({
  display: 'flex',
});

const StyledButton = styled(Button)(({ theme }) => ({
  color: "white",
  letterSpacing: "1px",
  fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  fontSize: "12px",
  fontWeight: '500',
  padding: '30px 16px',
  borderBottom: '2px solid transparent',
  borderRadius: '0',
  transition: 'border-bottom 0.15s ease-in-out',
  '&:hover': {
    borderBottom: '2px solid white',
    textDecoration: 'underline',
    color: '#3779CA',
    backgroundColor: 'transparent',
  },
  '&.active-nav-link, &.clicked-nav-link': {
    backgroundColor: '#3779CA',
    borderBottom: '2px solid white',
    transition: '0.15s',
    color: 'white',
  },
  [theme.breakpoints.down('md')]: {
   padding: '30px 14px',
  },
}));

// component content
const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

// state variables
  const [mobileAnchorEl, setMobileAnchorEl] = useState(null);
  const [activeSection, setActiveSection] = useState(null);
  const [isLinkClicked, setIsLinkClicked] = useState(false);

  const sections = useMemo(() => ['about', 'treatments', 'try-treatogether', 'find-physicians', 'patient-stories'], []);

  const OpenMobileMenu = (event) => {
    setMobileAnchorEl(event.currentTarget);
  };
  
  const CloseMobileMenu = () => {
    setMobileAnchorEl(null);
  };
  
  const handleLinkClick = (section) => {
    setActiveSection(section); // Highlight the clicked link immediately
    setIsLinkClicked(true); // Indicate that a link was clicked
  
    // Scroll to the desired section
    const element = document.getElementById(section);
    element.scrollIntoView({ behavior: 'instant' });
  
    // Allow scrolling behavior again after a delay
    setTimeout(() => {
      setIsLinkClicked(false);
    }, 500); // Adjust the delay as needed

    //close mobile menu if available
    CloseMobileMenu();
  };
    
  
  useEffect(() => {
    const handleScroll = () => {
      if (isLinkClicked) return; // Do not update the active section if a link was clicked
  
      let currentSection = null;
  
      sections.forEach((id) => {
        const element = document.getElementById(id);
        const rect = element.getBoundingClientRect();
        const offset = element.offsetHeight * 0.30; // 30% of the section height
  
        if (rect.top <= offset && rect.bottom >= offset) {
          currentSection = id;
        }
      });
  
      setActiveSection(currentSection);
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sections, isLinkClicked]);
  
  const mobileMenuId = 'menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(mobileAnchorEl)}
      onClose={CloseMobileMenu}
    >
      <MenuItem onClick={() => handleLinkClick('about')} className={activeSection === 'about' ? 'active-nav-link' : ''} component={Link} to="about" spy={true} offset={-70}>ABOUT</MenuItem>
      <MenuItem onClick={() => handleLinkClick('treatments')} className={activeSection === 'treatments' ? 'active-nav-link' : ''} component={Link} to="treatments" spy={true} offset={-70}>TREATMENTS</MenuItem>
      <MenuItem onClick={() => handleLinkClick('try-treatogether')} className={activeSection === 'try-treatogether' ? 'active-nav-link' : ''} component={Link} to="try-treatogether" spy={true} offset={-70}>TRY TREATOGETHER</MenuItem>
      <MenuItem onClick={() => handleLinkClick('find-physicians')} className={activeSection === 'find-physicians' ? 'active-nav-link' : ''} component={Link} to="find-physicians" spy={true} offset={-70}>FIND PHYSICIAN</MenuItem>
      <MenuItem onClick={() => handleLinkClick('patient-stories')} className={activeSection === 'patient-stories' ? 'active-nav-link' : ''} component={Link} to="patient-stories" spy={true} offset={-70}>PATIENT STORIES</MenuItem>
    </Menu>
  );

  return (
    <StyledAppBar>
      <StyledToolbar>
        <NavContainer>
        <StyledIconButton edge="start" color="inherit" aria-label="logo" href="/">
          <LogoImage src={Logo} alt="logo" />
        </StyledIconButton>
        </NavContainer>
        <NavContainer>
        {isMobile ? (
            <>
              <IconButton
                edge="end"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={OpenMobileMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              {renderMobileMenu}
            </>
          ) : (
            <>
              <StyledButton href="#about"  className={activeSection === 'about' ? 'active-nav-link' : ''} onClick={() => handleLinkClick('about')}>ABOUT</StyledButton>
              <StyledButton href="#treatments"  className={activeSection === 'treatments' ? 'active-nav-link' : ''} onClick={() => handleLinkClick('treatments')}>TREATMENTS</StyledButton>
              <StyledButton href="#try-treatogether"  className={activeSection === 'try-treatogether' ? 'active-nav-link' : ''} onClick={() => handleLinkClick('try-treatogether')}>TRY TREATOGETHER</StyledButton>
              <StyledButton href="#find-physicians"  className={activeSection === 'find-physicians' ? 'active-nav-link' : ''} onClick={() => handleLinkClick('find-physicians')}>FIND PHYSICIAN</StyledButton>
              <StyledButton href="#patient-stories"  className={activeSection === 'patient-stories' ? 'active-nav-link' : ''} onClick={() => handleLinkClick('patient-stories')}>PATIENT STORIES</StyledButton>
            </>
          )
        }
        </NavContainer>
      </StyledToolbar>
    </StyledAppBar>
  );
}

// export
export default Navbar;
