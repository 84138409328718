// @flow strict

// Libraries
import * as React from "react";

import { Popover as MuiPopover, Menu, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// import colors from "../../theming/colors";

// Constants
type PopoverPropsType = {
  anchorElement: React.Node,
  children: React.Node,
  anchorOrigin: Object,
  transformOrigin: Object,
  hover: Boolean,
  classes: Object,
  open?: boolean
};

const styles = theme => ({
  popover: {
    pointerEvents: "none"
  },
  paper: {
    padding: theme.spacing.unit,
    color: "grey",
    border: "2px solid lightgrey",
    borderRadius: "7px",
    font: "Lato, sans-serif",
    fontSize: "15px",
    overflow: "visible",
    boxShadow: "none",
    maxWidth: "290px",
    "&:after": {
      content: "' '", // eslint-disable-line
      position: "absolute",
      bottom: "-17.5px",
      left: "48%",
      height: 0,
      zIndex: 5,
      borderWidth: "8px",
      borderStyle: "solid",
      borderColor: "lightgrey transparent transparent transparent"
    }
  }
});

class Popover extends React.Component<PopoverPropsType> {
  static defaultProps = {
    open: false
  };

  constructor(props) {
    super(props);
    this.state = { anchorEl: null };
  }

  componentWillReceiveProps(nextProps) {
    const { open } = this.props;

    if (open && !nextProps.open) {
      this.handleClose();
    }
  }

  handleOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = event => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      anchorElement,
      children,
      anchorOrigin,
      transformOrigin,
      hover = false, // decides if the popover is triggered by hover or click
      classes
    } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    const popoverTrigger = hover ? (
      <span
        aria-owns={open ? "mouse-over-popover" : null}
        aria-haspopup="true"
        onMouseEnter={this.handleOpen}
        onMouseLeave={this.handleClose}
      >
        {" "}
        {anchorElement}
      </span>
    ) : (
      <div
        onClick={this.handleOpen}
        onKeyPress={() => undefined} // These 2 attributes are here to resolve an es-lint
        role="presentation" // -> Since this is not clean, we may need to think about resolving it in a different way in the future
      >
        {anchorElement}
      </div>
    );

    return (
      <React.Fragment>
        {popoverTrigger}
        <MuiPopover
          id="mouse-over-popover"
          className={hover ? classes.popover : null}
          classes={{ paper: classes.paper }}
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          disableRestoreFocus
        >
          {children}
        </MuiPopover>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Popover);
export { Menu, MenuItem };
