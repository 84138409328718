// @flow strict

import type { RadioGroupControlMapperType } from "./sharedTypes";

const radioGroupControlMappers: {
  boolean: RadioGroupControlMapperType,
  string: RadioGroupControlMapperType
} = {
  boolean: {
    getValue: formValue => {
      if (formValue === true) {
        return "true";
      }

      if (formValue === false) {
        return "false";
      }

      return undefined;
    },
    getUpdatedFormValues: (formValue, nextInputValue) => {
      if (nextInputValue === "true") {
        return true;
      }

      if (nextInputValue === "false") {
        return false;
      }

      return undefined;
    }
  },
  string: {
    getValue: formValue => formValue,
    getUpdatedFormValues: (formValue, nextInputValue) => nextInputValue
  }
};

export { radioGroupControlMappers };
