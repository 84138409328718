import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import StoneLogik from './StoneLogik';
import { TandC, PrivacyP } from './components/index';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<StoneLogik />} />
        <Route path="/terms-and-conditions" element={<TandC />} />
        <Route path="/privacy-policy" element={<PrivacyP />} />
      </Routes>
    </Router>
  );
}

export default App;
