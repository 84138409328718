// @flow strict

// Libraries
import * as React from "react";

// Relative Imports
import { FormProvider } from "./context";
import {
  updateHasNestedErrors,
  objectTreeImmutableSet,
  deepImmutableSetInputError,
  initSectionErrors,
  registerSection,
  flattenValues,
  flattenErrors,
  validateValues
} from "./utils";
import type {
  PathType,
  SetInputValueType,
  FormSectionApiType,
  FormStateType as SharedFormStateType,
  SetInputErrorType,
  FormSectionValidateType,
  SetValuesErrorsType,
  SetErrorType
} from "./sharedTypes";

/**
  This callback type is intended to be used for functions that should be executed
  after a state update.

  @callback afterUpdateCallback
*/

/**
  This callback type is intended to be used in scenarios where a form error/value
  update needs to have access to the most up to date formApi before updating.

  @callback formStateUpdaterCallback
  @param {object} prevApi - The most up-to-date form/section API before the
    update executes.
  @return {*} The next value(s)/error(s) to update to.
*/

// Prop types for the Form component
type FormPropsType = {
  // The default value to set the values object to on initialization.
  defaultValues?: any,

  // The component to render as the child of the form.
  component?: React.ComponentType<any>,

  // The props to pass to the component rendered as the child of the form.
  componentProps?: Object,

  // A render function used to render the children of the form.
  render?: FormSectionApiType => React.Node,

  // The children of the form.
  children?: (FormSectionApiType => React.Node) | React.Node,

  /**
    A callback that is triggered from the submit function found at any of the
    different form API levels.
  */
  onSubmit: (FormSectionApiType, submitTag: ?string) => void,

  /**
    A callback that is triggered any time a child of the form or the form itself
    calls for value changes to the form.
  */
  validate?: FormSectionValidateType,

  /**
    This prop acts as a way to synchronize values with an external store of some
    sort.

    If this is not undefined on intitialization and there is no default
    values, the values of the form are intialized to this.

    The Form can still manage its own state like normal if this prop is set, but
    any time it changes, the form values are updated to match the new storeValues.
  */
  storeValues?: any
};

type FormStateType = {
  values?: Object,
  errors?: Object,
  sectionRegister?: Object,
  valuesHaveChanged: boolean
};

/**
  A form component that works as an automatic state manager for inputs.
  In order to be managed by the form, inputs need to implement the connectInput
  HoC.
*/
class Form extends React.Component<FormPropsType, FormStateType> {
  static defaultProps = {
    defaultValues: undefined,
    component: undefined,
    componentProps: {},
    render: undefined,
    children: undefined,
    validate: undefined,
    storeValues: undefined
  };

  constructor(props: FormPropsType) {
    super(props);

    this.state = {
      values: {},
      errors: {
        __isSection: true
      },
      sectionRegister: {},
      valuesHaveChanged: false
    };

    if (props.defaultValues) {
      this.state.values = props.defaultValues;
    } else if (props.storeValues !== undefined) {
      this.state.values = props.storeValues;
    }
  }

  componentWillReceiveProps(nextProps: FormPropsType) {
    const { storeValues } = this.props;

    if (nextProps.storeValues !== storeValues) {
      this.setValues(nextProps.storeValues, {
        noValuesHaveChangedUpdate: true
      });
    }
  }

  /**
    This method sets the whole values object of the form.

    If there is a validate prop, it is called after this is triggered.

    @param {(object|formStateUpdaterCallback)} values - The new values to set
      the Form to, or the callback that returns the new values to set the Form to.
    @param {object} [options={}] - Additional options
    @param {afterUpdateCallback} options.afterUpdate - An optional function to
      call once the Form values have finished updating.
  */
  setValues: SetValuesErrorsType = (
    values,
    { afterUpdate, noValuesHaveChangedUpdate } = {}
  ) => {
    this.setState((state = { valuesHaveChanged: false, values: {} }) => {
      const prevFormApi = this.getFormApi(state);
      const { validate } = this.props;

      return {
        values: validateValues(
          prevFormApi,
          values,
          undefined,
          newValues => ({
            ...state.values,
            ...newValues
          }),
          validate
        ),
        valuesHaveChanged: noValuesHaveChangedUpdate
          ? state.valuesHaveChanged
          : true
      };
    }, afterUpdate);
  };

  /**
    This method updates the a value within the values object at the specified
    location.

    If there is a validate prop, it is called after this is triggered.

    @param {(string|string[])} inputPath - The path to location in the values
      object to update.
    @param {(*|formStateUpdaterCallback)} value - The new value to set at the
      specified location or the callback the returns the new value to set at the
      specified location.
    @param {object} [options={}] - Additional options
    @param {afterUpdateCallback} options.afterUpdate - An optional function to
      call once the Form value has finished updating.
  */
  setInputValue: SetInputValueType = (
    inputPath,
    value,
    { afterUpdate, noValuesHaveChangedUpdate } = {}
  ): void => {
    this.setState(state => {
      const prevFormApi = this.getFormApi(state);
      const { validate } = this.props;

      return {
        values: validateValues(
          prevFormApi,
          value,
          inputPath,
          newValues =>
            objectTreeImmutableSet(state.values, inputPath, newValues),
          validate
        ),
        valuesHaveChanged: noValuesHaveChangedUpdate
          ? state.valuesHaveChanged
          : true
      };
    }, afterUpdate);
  };

  /**
    This method generates the form API to be used externally at the form level.

    @param {object} [state=this.state] - The state to use to generate the form
      API.
    @return {object} The form API.
  */
  getFormApi = (state?: FormStateType = this.state): FormSectionApiType => ({
    ...state,
    flatValues: flattenValues(state.sectionRegister, state.values),
    flatErrors: flattenErrors(state.sectionRegister, state.errors),
    onChange: this.setValues,
    onChangeInput: this.setInputValue,
    setError: this.setError,
    setInputError: this.setInputError,
    submit: this.submit
  });

  /**
    This method generates the form state. This gets passed down to child sections
    and inputs internally through context.

    @return {object} The form state.
  */
  getFormState = (): SharedFormStateType => ({
    ...this.state,
    setInputValue: this.setInputValue,
    setInputError: this.setInputError,
    initSectionErrors: this.initSectionErrors,
    registerSection: this.registerSection,
    submit: this.submit
  });

  /**
    This method sets the error of the form.

    @param {(*|formStateUpdaterCallback)} error - The new error to set the
      Form to, or a callback that returns the new error to set the Form to.
    @param {object} [options={}] - Additional options
    @param {afterUpdateCallback} options.afterUpdate - An optional function to
      call once the Form error has finished updating.
  */
  setError: SetErrorType = (error, { afterUpdate } = {}) => {
    this.setState(state => {
      const _error =
        typeof error === "function" ? error(this.getFormApi(state)) : error;

      return {
        errors: {
          ...state.errors,
          error: _error
        }
      };
    });
    this.updateHasNestedErrors(afterUpdate);
  };

  /**
    This method sets the errors object of the form.

    @param {(object|formStateUpdaterCallback)} errors - The new errors to set the
      Form to, or a callback that returns the new errors to set the Form to.
    @param {object} [options={}] - Additional options
    @param {afterUpdateCallback} options.afterUpdate - An optional function to
      call once the Form errors have finished updating.
  */
  setErrors: SetValuesErrorsType = (errors, { afterUpdate } = {}) => {
    this.setState(state => {
      const _errors =
        typeof errors === "function" ? errors(this.getFormApi(state)) : errors;
      return { errors: _errors };
    });
    this.updateHasNestedErrors(afterUpdate);
  };

  /**
    This method sets the error of the input or section at the specified location.

    @param {(string|string[])} inputPath - The path to location in the errors
      object to set the error.
    @param {(*|formStateUpdaterCallback)} error - The new error to set at
      the specified location, or a callback that returns the new error to set
      at the specified location.
    @param {object} [options={}] - Additional options
    @param {afterUpdateCallback} options.afterUpdate - An optional function to
      call once the Form errors have finished updating.
    @param {bool} [options.setSectionErrors=false] - If this is true, then the end of the
      path will be assumed to land on a section rather than an input, and all
      errors of that section will be updated.
  */
  setInputError: SetInputErrorType = (
    inputPath,
    error,
    { setSectionErrors = false, afterUpdate } = {}
  ) => {
    this.setState(state => {
      const _error =
        typeof error === "function" ? error(this.getFormApi(state)) : error;

      return {
        errors: deepImmutableSetInputError(state.errors, inputPath, _error, {
          setSectionErrors
        })
      };
    });
    this.updateHasNestedErrors(afterUpdate);
  };

  /**
    This method intializes a section's key in the errors object.

    @param {(string|string[])} sectionPath - The path to the section to initialize.
  */
  initSectionErrors = (sectionPath: PathType): void => {
    this.setState(state => ({
      errors: initSectionErrors(state.errors, sectionPath)
    }));
  };

  /**
    This method registers a section in the section register.

    @param {(string|string[])} sectionPath - The path to the section to register.
  */
  registerSection = (sectionPath: PathType): void => {
    this.setState(state => ({
      sectionRegister: registerSection(state.sectionRegister, sectionPath)
    }));
  };

  /**
    This method calls the onSubmit prop passed to the Form if there is one.
  */
  submit = (submitTag: ?string): void => {
    const { onSubmit } = this.props;
    if (typeof onSubmit === "function") {
      onSubmit(this.getFormApi(), submitTag);
    }
  };

  /**
    This method updates all the hasNestedErrors keys of the sections and the form
    within the errors object.

    @param {afterUpdateCallback} afterUpdate - An optional function to
      call once the Form errors have finished updating.
  */
  updateHasNestedErrors = (afterUpdate?: () => void): void => {
    this.setState(
      state => ({
        errors: updateHasNestedErrors(state.errors)
      }),
      afterUpdate
    );
  };

  render() {
    const {
      component: Component,
      componentProps,
      render,
      children
    } = this.props;

    const formApi = this.getFormApi();
    const formState = this.getFormState();

    if (Component) {
      return (
        <FormProvider value={formState}>
          <Component formApi={formApi} {...componentProps} />
        </FormProvider>
      );
    }

    if (render) {
      return <FormProvider value={formState}>{render(formApi)}</FormProvider>;
    }

    if (typeof children === "function") {
      return <FormProvider value={formState}>{children(formApi)}</FormProvider>;
    }

    return <FormProvider value={formState}>{children}</FormProvider>;
  }
}

export default Form;
