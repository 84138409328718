import styled from "../node_modules/styled-components/macro";
import { Button } from "@material-ui/core";

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: auto;
  width: 100%;
  text-align: left;
  background-color: #5AAF5E;
  margin: 0 auto 32px auto;
  padding: 80px 0;
  color: #212529 ;
`;
  
const TitleSection = styled.div`
  margin-top: 20px;
  display: flex;
`;

const Title = styled.label`
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 54px;
  line-height: 1.2;
  font-family: Poppins, sans-serif;
  color: white;
  text-align: center;
  padding: 0 32px;

  @media (max-width: 1920px) {
    font-size: 36px;
  }

  @media (max-width: 1280px) {
    font-size: 36px;
  }

  @media (max-width: 960px) {
    font-size: 34px;
  }

  @media (max-width: 600px) {
    font-size: 32px;
  }

  @media (max-width: 400px) {
    margin-bottom: 32px;
  }
`;

const AppContentWrapper = styled.div`
  justify-content: center;
  width: 90%;
  max-width: 1000px;
`;

const Container1 = styled.div`
  min-height: ${props => (props.patient ? "689px" : "800px")};
  min-width: 265px;
  margin-top: 24px;
  color: #212529;
`;

const Title1 = styled.span`
  font-weight: 700;
  font-size: 18px;
  padding: 0 28px;
  color: #212529;
  font-family: Lato,sans-serif;

   @media (max-width: 750px) {
     padding: 0 16px;
   }
`;

const SubTitle1 = styled.span`
  font-weight: 300;
  font-size: 16px;
  padding: 0 28px;
  color: white;
  font-family: Lato,sans-serif;

  @media (max-width: 750px) {
    padding: 0 16px;
  }
`;

const Divider = styled.span`
  background: #eeeeee;
  height: 1.5px;
  margin-top: 6px;
  max-width: 1000px;
  width: 100%;

  @media (max-width: 300px) {
    width: 95%;
  }
`;

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  padding: 0 30px 30px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    padding: 0 16px 16px;
  }
`;

const FormRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "label input";
  align-items: center;
`;

const Label = styled.span`
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-right: 10px;
  color: white;
  font-family: Poppins,sans-serif;
`;

const Circle = styled.span`
  border-radius: 50%;
  height: 16px;
  width: 16px;
  background-color: white;
  border: grey solid 1.5px;
  color: grey;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-weight: normal;
  font-size: 11px;
  margin-top: 12px;
`;

const SubTitle2 = styled.span`
  font-weight: 400;
  font-size: 12px;
  margin-left: 4px;
  color: white;
`;

const ResponsiveFlex = styled.span `
  display: flex;
  flex-direction: row;

  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

const ButtonsFlex = styled.div `
  padding-right: 14px;

  @media (max-width: 400px) {
   align-items: center;
   margin-bottom: 10px;
   padding: 0;
  }
`;

const StyledButton = styled(Button)({
  width: '100%',
  maxWidth: '180px',
  borderRadius: '20px',
  padding: '12px 16px',
  fontWeight: 'normal',
  fontSize: '16px',
  color: "#212529",
  backgroundColor: 'white',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  '&:hover': {
    backgroundColor: "#D9D9DB",
    color: "#3779CA",
  },
  '&:active': {
    border: 'none',
  },
});

const ResultsContainer1 = styled.div`
  display: block;
  margin: 60px auto;
  width: 90%;
  padding: 10px 42px 25px;
  background: white;
  border-radius: 10px;
  text-align: left;
  font-weight: normal;
  color: #212529;
  max-width: 1000px;

  @media (max-width: 1280px) {
    padding: 10px 24px 25px;
  }

  @media (max-width: 960px) {
    padding: 10px 24px 25px;
  }
  
  @media (max-width: 600px) {
    padding: 10px 16px 25px;
  }
  
  @media (max-width: 400px) {
    padding: 10px 12px 25px;
  }
`;
  
const Recommend = styled.span`
font-family: Poppins, sans-serif;
color: #5aaf5e;
font-size: 50px;
line-height: 48px;
font-weight: normal;
padding-bottom: 25px;
height: auto;

@media (max-width: 600px) {
  font-size: 48px;
}
@media (max-width: 350px) {
  font-size: 38px;
}
`;

const Message = styled.span`
font-family: Poppins, sans-serif;
color: black;
line-height: 15px;
font-style: italic;
font-weight: normal;
line-height: 28px;
padding-bottom: 15px;
`;

const Info = styled.span`
font-family: Poppins, sans-serif;
color: black;
line-height: 15px;
font-weight: 300;
line-height: 28px;
`;

const Result = styled.span`
  font-size: 36px;
  line-height: 50px;
  color: #acacac;
  margin-bottom: 1rem;
  font-family: Poppins, sans-serif;
  font-weight: 300;

  @media (max-width: 600px) {
    font-size: 34px;
  }
  @media (max-width: 350px) {
    font-size: 32px;
  }
`;

export {
  AppContentWrapper,
  AppWrapper,
  Title,
  TitleSection,
  Circle,
  Container1,
  FormContainer,
  ResultsContainer1,
  Label,
  Title1,
  FormRow,
  SubTitle1,
  SubTitle2,
  Divider,
  Recommend,
  Message,
  Info,
  Result,
  ResponsiveFlex,
  ButtonsFlex,
  StyledButton
};
