// @flow strict

// Libraries
import * as React from "react";
import classNames from "classnames";
import {
  Input as BaseInput,
  FormHelperText,
  FormControl,
  withStyles
} from "@material-ui/core";

// Relative Imports
import { connectInput } from "../Form";
import type { InputApiType } from "../Form/sharedTypes";

const inputStyles = {
  input: {
    width: "100%"
  }
};

const Input = withStyles(inputStyles)(BaseInput);

const textboxStyles = (theme = {}) => {
  const { custom = {} } = theme;
  const { textbox = {} } = custom;

  return textbox;
};

type TextboxPropsType = {
  error?: React.Node,
  classes: {
    disabled?: string,
    root: string,
    adornment?: string
  },
  value?: ?string,
  disabled?: boolean,
  multiline?: boolean,
  rows?: number,
  placeholder?: string,
  fullWidth?: boolean,
  onChange?: Function,
  style?: Object,
  endAdornment?: React.Node,
  onKeyUp?: () => void,
  onKeyDown?: () => void,
  onBlur?: Function,
  type?: string
};

const Textbox = withStyles(textboxStyles)(
  ({
    error,
    classes,
    value,
    disabled,
    multiline,
    rows,
    placeholder,
    fullWidth,
    onChange,
    style,
    endAdornment,
    onKeyUp,
    onBlur,
    type,
    onKeyDown
  }: TextboxPropsType) => (
    <FormControl error={Boolean(error)} fullWidth={fullWidth} style={style}>
      <Input
        disableUnderline
        className={classNames(disabled ? classes.disabled : "", classes.root)}
        value={value}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        placeholder={placeholder}
        fullWidth={fullWidth}
        onChange={onChange}
        onBlur={onBlur}
        type={type}
        endAdornment={
          endAdornment ? (
            <span className={classes.adornment}>{endAdornment}</span>
          ) : (
            undefined
          )
        }
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
      />
      {error ? <FormHelperText>{error}</FormHelperText> : undefined}
    </FormControl>
  )
);

Textbox.defaultProps = {
  error: undefined,
  formControlProps: {},
  formHelperTextProps: {},
  value: undefined,
  disabled: false,
  classes: {},
  multiline: false,
  rows: 1,
  placeholder: "",
  fullWidth: false,
  onChange: () => {},
  style: undefined,
  onKeyUp: () => {},
  onKeyDown: undefined,
  type: "text"
};

type FormTextboxBasePropsType = {
  ...TextboxPropsType,
  formApi: InputApiType,
  restrictedKeys?: Array
};

const FormTextboxBase = ({ formApi, ...rest }: FormTextboxBasePropsType) => {
  const { value = "", onChange, error } = formApi;

  return (
    <Textbox
      {...rest}
      value={value}
      onChange={e => onChange(e.target.value)}
      error={error}
    />
  );
};

FormTextboxBase.defaultProps = {
  restrictedKeys: []
};

const FormTextbox = connectInput(FormTextboxBase, {
  defaultName: "textbox",
  bundleApi: true
});

export default Textbox;
export { FormTextbox };
