// Libraries
import styled from "styled-components/macro";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > *:not(:first-child) {
    margin-top: 15px;
  }
`;

export { Wrapper };
