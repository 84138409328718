// @flow strict

// Libraries
import * as React from "react";
import { Radio as MuiRadio, RadioGroup, withStyles } from "@material-ui/core";
import type { Radio as RadioType } from "@material-ui/core";

// Alias Imports
import {
  SelectedIcon,
  UnselectedIcon
} from "../../images/iconComponents/radio-button";

// Relative Imports
import { connectInput } from "../Form";
import { radioGroupControlMappers } from "./utils";
import type { InputApiType } from "../Form/sharedTypes";
import type { RadioGroupControlMapperType } from "./sharedTypes";

const { string: stringControlMapper } = radioGroupControlMappers;

type RadioPropsType = {
  checked?: boolean,
  disabled?: boolean,
  error?: boolean,
  onChange?: (event: SyntheticInputEvent<>, checked: boolean) => void,
  value: ?string
};

const Radio = ({
  checked,
  disabled,
  error,
  onChange,
  value
}: RadioPropsType): RadioType => (
  <MuiRadio
    icon={<UnselectedIcon disabled={disabled} error={error} />}
    checkedIcon={<SelectedIcon disabled={disabled} />}
    checked={checked}
    disabled={disabled}
    onChange={onChange}
    value={value}
  />
);

Radio.defaultProps = {
  checked: undefined,
  disabled: false,
  error: false,
  onChange: undefined
};

type FormRadioGroupBasePropsType = {
  formApi: InputApiType,
  children?: React.Node,
  classes: { root: string },
  evenlySpaced?: boolean,
  row?: boolean,
  controlMapper?: RadioGroupControlMapperType
};

const formGroupSpacedStyles = {
  root: {
    justifyContent: "space-between"
  }
};

const FormRadioGroupBase = ({
  formApi,
  children,
  classes,
  evenlySpaced,
  row,
  controlMapper = stringControlMapper
}: FormRadioGroupBasePropsType) => {
  const { value, onChange } = formApi;
  const {
    getValue = stringControlMapper.getValue,
    getUpdatedFormValues = stringControlMapper.getUpdatedFormValues
  } = controlMapper;

  return (
    <RadioGroup
      value={getValue(value)}
      onChange={e => onChange(getUpdatedFormValues(value, e.target.value))}
      row={row}
      className={evenlySpaced ? classes.root : ""}
    >
      {children}
    </RadioGroup>
  );
};

FormRadioGroupBase.defaultProps = {
  children: undefined,
  row: false,
  evenlySpaced: false,
  controlMapper: stringControlMapper
};

const FormRadioGroupBaseWithStyles = withStyles(formGroupSpacedStyles)(
  FormRadioGroupBase
);

const FormRadioGroup = connectInput(FormRadioGroupBaseWithStyles, {
  defaultName: "radioGroup",
  bundleApi: true
});

export default Radio;
export { RadioGroup, FormRadioGroup };
