import colors from "./colors";

const muiTheme = {
  typography: {
    fontFamily: "Lato, 'Helvetica Neue', Arial, sans-serif",
    useNextVariants: true
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: "none"
      },
      colorPrimary: {
        color: colors.black,
        backgroundColor: colors.white
      }
    },
    MuiSvgIcon: {
      root: {
        fontSize: "30px"
      }
    },
    MuiToolbar: {
      root: {
        margin: "0 auto"
      }
    },
    MuiTabs: {
      root: {
        backgroundColor: colors.blue.baseDark,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        boxShadow:
          "0 1px 1px 0 rgba(0,0,0,0.24), 0 1px 1.5px 0 rgba(0,0,0,0.12)"
      },
      indicator: {
        backgroundColor: colors.white
      }
    },
    MuiTab: {
      root: {
        textTransform: "none",
        color: colors.white,
        width: "50%"
      },
      label: {
        fontSize: "16px !important",
        color: colors.white,
        paddingTop: 14,
        paddingBottom: 12
      },
      labelIcon: {
        height: "50px"
      },
      textColorInherit: {
        color: colors.white,
        opacity: 0.8
      },
      wrapper: {
        flexDirection: "row"
      },
      labelContainer: {
        padding: "0 !important"
      }
    },
    MuiTypography: {
      headline: {
        fontSize: 24
      },
      title: {
        fontWeight: "bold",
        fontSize: 22
      },
      subheading: {
        fontWeight: "bold",
        fontSize: 18
      },
      body1: {
        fontSize: "18px"
      },
      display1: {
        color: colors.black,
        fontSize: "19px"
      },
      display2: {
        color: colors.black,
        fontSize: "22px"
      },
      display3: {
        color: colors.black
      },
      display4: {
        color: colors.black
      },
      colorSecondary: {
        color: colors.grey.medium
      }
    },
    MuiButton: {
      root: {
        borderRadius: 8,
        textTransform: "none",
        fontSize: "18px",
        padding: "8px 10px",
        minHeight: "45px",
        minWidth: "112px"
      },
      sizeLarge: {
        fontSize: "18px",
        padding: "8px 10px",
        minHeight: "45px",
        width: "407px"
      },
      fab: {
        width: 44,
        height: 44
      },
      mini: {
        width: 36,
        height: 36
      }
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
        marginRight: 0,
        "&$disabled": {
          color: `${colors.black} !important`
        }
      },
      label: {
        fontSize: 18
      }
    },
    MuiCheckbox: {
      root: {
        marginRight: 10,
        marginTop: 5,
        "&$checked": {
          color: `${colors.grey.medium} !important`
        }
      }
    },
    MuiRadio: {
      root: {
        marginRight: 10,
        marginTop: 5,
        "&$checked": {
          color: `${colors.white} !important`
        }
      }
    },
    MuiRadioGroup: {
      root: {
        padding: 0
      }
    },
    MuiInput: {
      root: {
        border: `1px solid ${colors.grey.light}`,
        borderRadius: 5,
        fontSize: 18,
        padding: 1,
        height: 40,
        "&$focused": {
          borderColor: colors.blue.dark,
          borderWidth: 2,
          padding: 0
        },
        "&$disabled": {
          color: colors.black,
          backgroundColor: colors.grey.base
        }
      },
      input: {
        padding: "6px 8px 7px"
      },
      multiline: {
        padding: "6px 8px 7px"
      },
      error: {
        borderColor: colors.red.medium,
        borderWidth: 2,
        padding: 0
      }
    },
    MuiTablePagination: {
      input: {
        height: "40px",
        width: "78px",
        borderRadius: "7px",
        borderColor: colors.grey.light
      },
      select: {
        fontSize: "18px",
        paddingLeft: "0px",
        paddingRight: "35px",
        marginTop: "5px",
        marginLeft: "12px"
      },
      selectRoot: {
        marginLeft: 0,
        marginRight: 0,
        color: colors.black
      },
      caption: {
        fontSize: "16px",
        marginRight: "10px",
        marginLeft: "10px",
        color: colors.black
      },
      selectIcon: {
        height: "35px",
        width: "35px"
      },
      actions: {
        color: `${colors.black} !impotant`
      }
    },
    MuiIconButton: {
      root: {
        color: colors.black,
        width: 42,
        height: 42,
        padding: 0,
        "&:hover": {
          backgroundColor: colors.grey.light
        },
        "&:focus": {
          backgroundColor: colors.grey.light
        }
      }
    },
    MuiFormHelperText: {
      root: {
        fontSize: 14
      },
      error: {
        color: `${colors.red.medium} !important`
      }
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "transparent"
        },
        "&$disabled": {
          color: colors.black
        }
      },
      icon: {
        top: "calc(50% - 15px)",
        color: "inherit"
      }
    },
    MuiMenuItem: {
      root: {
        "&:hover": {
          backgroundColor: colors.blue.light
        },
        "&:focus": {
          backgroundColor: colors.blue.light
        },
        "&$selected": {
          backgroundColor: `${colors.blue.light} !important`
        }
      }
    },
    MuiTable: {
      root: {
        borderCollapse: "separate"
      }
    },
    MuiTableRow: {
      root: {
        height: 0,
        border: "none"
      },
      head: {
        height: 0
      },
      hover: {
        "&:hover": {
          backgroundColor: `${colors.blue.light} !important`
        }
      }
    },
    MuiToggleButton: {
      root: {
        color: `${colors.grey.medium} !important`,
        backgroundColor: colors.white,
        width: "42px",
        height: "42px",
        minWidth: "42px",
        textTransform: "none",
        fontSize: "16px",
        fontWeight: "normal",
        alignItems: "center",
        justifyContent: "center",
        "&$selected": {
          backgroundColor: `${colors.blue.dark} !important`,
          color: `${colors.white} !important`,
          "&:after": {
            opacity: 0
          }
        }
      }
    },
    MuiToggleButtonGroup: {
      root: {
        borderWidth: "1px",
        borderColor: `${colors.grey.light} !important`,
        borderStyle: "solid",
        borderRadius: "8px"
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: "none",
        color: colors.black,
        verticalAlign: "top",
        "&:first-child": {
          paddingLeft: "25px"
        },
        "&:last-child": {
          paddingRight: "20px"
        }
      },
      head: {
        padding: "15px 14px 20px",
        fontSize: "18px",
        fontWeight: "bold",
        color: colors.black
      },
      body: {
        padding: "10px 14px",
        fontSize: "16px"
      }
    },
    MuiTableSortLabel: {
      root: {
        color: colors.black,
        fontWeight: "bold",
        fontSize: "17px"
      },
      icon: {
        width: 18,
        height: 18
      }
    },
    MuiDialog: {
      root: {
        paddingRight: "0 !important"
      },
      paper: {
        borderRadius: "8px",
        marginLeft: 10,
        marginRight: 10
      }
    },
    MuiFormLabel: {
      root: {
        color: colors.black,
        "&$focused": {
          color: colors.black
        }
      }
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: colors.blue.dark
      }
    },
    MuiFormGroup: {
      root: {}
    }
  },
  custom: {
    button: {
      colors: {
        flatPrimary: {
          color: colors.white,
          backgroundColor: colors.blue.dark,
          "&:hover": {
            backgroundColor: colors.blue.mediumLight
          }
        },
        flatSecondary: {
          color: colors.blue.dark,
          backgroundColor: colors.white,
          borderStyle: "solid",
          border: 2,
          borderColor: colors.blue.dark,
          "&:hover": {
            backgroundColor: colors.blue.light
          }
        },
        flatConfirm: {
          color: colors.green.medium,
          backgroundColor: colors.white,
          borderStyle: "solid",
          border: 2,
          borderColor: colors.green.medium,
          "&:hover": {
            backgroundColor: colors.green.light
          }
        },
        flatWarning: {
          color: colors.orange.mediumDark,
          backgroundColor: colors.white,
          borderStyle: "solid",
          border: 2,
          borderColor: colors.orange.mediumDark,
          "&:hover": {
            backgroundColor: colors.orange.light
          }
        },
        flatDanger: {
          color: colors.red.medium,
          backgroundColor: colors.white,
          borderStyle: "solid",
          border: 2,
          borderColor: colors.red.medium,
          "&:hover": {
            backgroundColor: colors.red.light
          }
        },
        flatDisabled: {
          color: colors.grey.medium,
          backgroundColor: colors.grey.light
        },
        secondaryFlatDisabled: {
          color: colors.grey.medium,
          borderColor: colors.grey.light
        },
        primaryRipple: {
          backgroundColor: colors.blue.mediumDark
        },
        confirmRipple: {
          backgroundColor: colors.green.dark
        },
        warningRipple: {
          backgroundColor: colors.orange.dark
        },
        dangerRipple: {
          backgroundColor: colors.red.dark
        }
      }
    },
    select: {
      root: {
        backgroundColor: colors.white
      },
      placeholderText: {
        root: {
          color: colors.grey.medium
        }
      },
      menu: {
        paper: {
          boxShadow: "none",
          border: `2px solid ${colors.blue.dark}`,
          borderRadius: 5
        }
      }
    },
    textbox: {
      root: {
        backgroundColor: colors.white,
        fontSize: 18,
        height: 40,
        borderRadius: 7
      },
      disabled: {
        backgroundColor: colors.grey.light,
        color: colors.black
      },
      adornment: {
        padding: "6px 8px 7px 0",
        color: colors.grey.medium,
        pointerEvents: "none"
      }
    }
  }
};

export default muiTheme;
