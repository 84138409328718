import React from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components/macro";

import customMuiTheme from "../../theming/muiTheme";
import styledComponentTheme from "../../theming/styledComponentTheme";

const muiTheme = createMuiTheme(customMuiTheme);

type Props = {
  children: React.Node
};

function ThemingWrapper({ children }: Props) {
  return (
    <MuiThemeProvider theme={muiTheme}>
      <ThemeProvider theme={styledComponentTheme}>{children}</ThemeProvider>
    </MuiThemeProvider>
  );
}

export default ThemingWrapper;
