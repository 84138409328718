// imports
import React from 'react';
import { Button, Typography, Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import backgroundImage from '../../images/hero.png'

// styled divs saved to variables
const HeroSection = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '80vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    height: '100vh'
  },
  [theme.breakpoints.down('sm')]: {
   height: '80vh',
  },
  [theme.breakpoints.down('xs')]: {
   marginTop: '2.5rem',
  },
}));

const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  backgroundColor: 'rgba(39, 109, 196, 0.7)',
});

const Content = styled(Box)(({ theme }) => ({
  position: 'relative',
  textAlign: 'left',
  maxWidth: '1000px',
  [theme.breakpoints.down('xs')]: {
   textAlign: 'center',
   padding: '0 18px'
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '60px',
  fontWeight: 'bolder',
  fontFamily: "Poppins, sans-serif",
  color: 'white',
  [theme.breakpoints.down('md')]: {
   fontSize: '50px',
  },
  [theme.breakpoints.down('sm')]: {
   fontSize: '40px',
  },
  [theme.breakpoints.down('xs')]: {
   fontSize: '36px',
   fontWeight: 'bold',
   marginBottom: '18px',
  },
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: '26px',
  marginBottom: '32px',
  fontWeight: 400,
  color: 'white',
  fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  [theme.breakpoints.down('xs')]: {
   fontSize: '20px',
  },
}));

const HeroButton = styled(Button)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 400,
  color: 'white',
  padding: "20px 30px 18px",
  borderRadius: "20px",
  background: "#5aaf5e",
  fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  '&:hover': {
    backgroundColor: "#d9d9db",
    color: "#3779CA",
  },
  [theme.breakpoints.down('sm')]: {
    padding: '16px 20px 14px',
  },
  [theme.breakpoints.down('xs')]: {
   fontSize: '14px',
  },
}));

// component content
const Hero = () => {
  return (
    <HeroSection>
      <Overlay />
      <Content>
        <Title variant="h2" component="h1">
          Don't Settle For Uncertainty
        </Title>
        <Subtitle variant="h5" component="h2">
          Know your kidney stone treatment options.
        </Subtitle>
        <HeroButton variant="contained" color="tertiary" href="#treatments">
          EXPLORE MY TREATMENT OPTIONS
        </HeroButton>
      </Content>
    </HeroSection>
  );
}

// export
export default Hero;
