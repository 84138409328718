// @flow strict

import * as React from "react";

import { FormConsumer } from "./context";
import type {
  FormStateType,
  BaseUpdateOptionsType,
  InputApiType
} from "./sharedTypes";

type InputUpdateType = (value?: any, options?: BaseUpdateOptionsType) => void;

type InputValidateCallbackType = (
  (value?: any) => void,
  value?: any,
  InputApiType
) => void;

type FormConnectorPropsType = {
  // The name used to uniquely identify the input within the Form
  name: string,

  // The default value to set the input value to on initialization.
  defaultValue?: any,

  /**
    A callback that is triggered any time the input calls for a value change
    to the form.
  */
  validate?: InputValidateCallbackType,

  // The component to render as the base input of the connected input.
  component: React.ComponentType<any>,

  // The form state object from the parent form/section
  formState: FormStateType,

  /**
    This options object describes various settings of the connector.

    The bundleApi key is a bool that when true takes the input-level form API
    and bundles it into a single prop passed using the bundleKey as the prop name
    to the base input.

    The bundleKey string is the prop name to use when bundling the input-level
    form API to pass to the base input.
  */
  options: { bundleApi?: boolean, bundleKey?: string },

  // An object conatining any custom props to pass to the base input that is rendered.
  inputProps: Object,

  /**
    This prop acts as a way to synchronize the input value with an external store of some
    sort.

    If this is not undefined on intitialization and there is no default
    value, the value of the input are intialized to this.

    The input can still manage its own state like normal if this prop is set, but
    any time it changes, the input's value is updated to match the new storeValue.
  */
  storeValue?: any
};

/**
  The FormConnector is intended for giving the connected input access to the state
  API of the most immediate Form or Section parent.
*/
class FormConnector extends React.Component<FormConnectorPropsType> {
  static defaultProps = {
    defaultValue: undefined,
    validate: undefined,
    storeValue: undefined
  };

  constructor(props) {
    super(props);

    const {
      name,
      defaultValue,
      formState: { values: { [name]: value } = {}, setInputValue } = {},
      storeValue
    } = props;

    if (defaultValue !== undefined && value === undefined) {
      setInputValue(name, defaultValue, { noValuesHaveChangedUpdate: true });
    } else if (storeValue !== undefined && value === undefined) {
      /*
        HACK: Protect against overwriting existing Form values with store values
        on mount.
      */
      setInputValue(name, storeValue, { noValuesHaveChangedUpdate: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { storeValue } = this.props;

    if (nextProps.storeValue !== storeValue) {
      this.onChange(nextProps.storeValue, { noValuesHaveChangedUpdate: true });
    }
  }

  /**
    This method sets the value of the input in the form.

    If there is a validate prop, it is called after this is triggered.

    It uses the setValue method.

    @param {*} value - The new value to set the input to.
    @param {object} [options={}] - Additional options
    @param {afterUpdateCallback} options.afterUpdate - An optional function to
      call once the input value has finished updating.
  */
  onChange: InputUpdateType = (
    value,
    { afterUpdate, noValuesHaveChangedUpdate } = {}
  ) => {
    const { validate } = this.props;

    if (typeof validate === "function") {
      const confirmChange = (confirmedValue = value) =>
        this.setValue(confirmedValue, { afterUpdate });

      validate(confirmChange, value, this.getApi());
    } else {
      this.setValue(value, { afterUpdate, noValuesHaveChangedUpdate });
    }
  };

  /**
    This method sets the value of the input in the form.

    @param {*} value - The new value to set the input to.
    @param {object} [options={}] - Additional options
    @param {afterUpdateCallback} options.afterUpdate - An optional function to
      call once the input value has finished updating.
  */
  setValue: InputUpdateType = (
    value,
    { afterUpdate, noValuesHaveChangedUpdate } = {}
  ): void => {
    const { name, formState: { setInputValue } = {} } = this.props;

    setInputValue(name, value, { afterUpdate, noValuesHaveChangedUpdate });
  };

  /**
    This method generates the form API to be used externally at the input level.

    @return {object} The input-level form API.
  */
  getApi = (): InputApiType => {
    const {
      name,
      formState: {
        values: { [name]: value } = {},
        errors: { nested: { [name]: error } = {} } = {},
        submit
      }
    } = this.props;

    return {
      value,
      error,
      onChange: this.onChange,
      setError: this.setError,
      submit
    };
  };

  /**
    This method sets the error of the input within the form.

    @param {*} error - The new error to set the input to.
    @param {object} [options={}] - Additional options
    @param {afterUpdateCallback} options.afterUpdate - An optional function to
      call once the input error has finished updating.
  */
  setError: InputUpdateType = (error, { afterUpdate } = {}) => {
    const { name, formState: { setInputError } = {} } = this.props;

    setInputError(name, error, { afterUpdate });
  };

  render() {
    const { component: Component, options, inputProps } = this.props;

    const { bundleApi = false, bundleKey = "formApi" } = options;

    let api = this.getApi();

    if (bundleApi) {
      api = {
        [bundleKey]: api
      };
    }

    return <Component {...api} {...inputProps} />;
  }
}

type ConsumerMapperPropsType<BasePropsType> = {
  ...$Exact<BasePropsType>,
  name?: string,
  validate?: InputValidateCallbackType,
  defaultValue?: any,
  storeValue?: any
};

/**
  The connectInput HoC generates a component that when rendered will automatically
  connect the base input provided to the HoC to the immediate Form/Section parent
  of the input using context.

  @param {Component} Component - The base input to wrap with the Form functionality.
  @param {object} [options={}] - An options object for the HoC.
  @param {string} [options.defaultName=""] - A string to use as the input's
    default name if one isn't provided.
  @param {function} options.defaultValidate - A validation function to use as a default
    should no validate function be provided to the input.
  @param {bool} [options.bundleApi=false] - When true, the connected input takes
    the input-level form API and bundles it into a single prop passed using the
    bundleKey as the prop name to the base input.
  @param {string} [options.bundleKey="formApi"] - The prop name to use when
    bundling the input-level form API to pass to the base input.
  @return {consumerMapper} The connected input component.
*/
function connectInput<InputPropsType: {}>(
  Component: React.ComponentType<InputPropsType>,
  options?: {
    defaultName?: string,
    defaultValidate?: InputValidateCallbackType,
    defaultValue?: any,
    bundleApi?: boolean,
    bundleKey?: string
  } = {}
): React.ComponentType<ConsumerMapperPropsType<InputPropsType>> {
  const {
    defaultName = "",
    defaultValidate,
    defaultValue: defaultDefaultValue,
    ...connectorOptions
  } = options;

  /**
    The consumer mapper grabs the form state from the most immediate Form/Section
    parent through context and passes it to the FormConnector that renders the
    base input component.
  */
  function consumerMapper({
    // Defaults are duplicated since flow and defaultProps rules conflict
    name = defaultName,
    validate,
    defaultValue = defaultDefaultValue,
    storeValue,
    ...inputProps
  }: ConsumerMapperPropsType<InputPropsType>): React.Node {
    return (
      <FormConsumer>
        {formState => (
          <FormConnector
            component={Component}
            name={name}
            defaultValue={defaultValue}
            validate={validate === undefined ? defaultValidate : validate}
            formState={formState}
            inputProps={inputProps}
            options={connectorOptions}
            storeValue={storeValue}
          />
        )}
      </FormConsumer>
    );
  }
  consumerMapper.defaultProps = {
    name: defaultName,
    validate: undefined,
    defaultValue: defaultDefaultValue,
    storeValue: undefined
  };

  return consumerMapper;
}

export default connectInput;
