// imports
import React from 'react';
import { Typography, Box, Link, List, ListItem } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { RegNavbar, RegFooter } from '../index';

// styled divs set to variables
const Section = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left',
    maxWidth: '960px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '80px 5% 60px',
    [theme.breakpoints.down('md')]: {
     padding: "60px 2.5% 36px"
    },
    [theme.breakpoints.down('sm')]: {
      padding: "42px 5% 24px",
    },
    [theme.breakpoints.down('xs')]: {
      padding: "24px 5% 24px",
    },
}));

const Title = styled(Typography)(({ theme }) => ({
    fontSize: '35px',
    fontWeight: 500,
    margin: '60px 0 24px',
    lineHeight: '44px',
    fontFamily: "Poppins, sans-serif",
    [theme.breakpoints.down('sm')]: {
        fontSize: '32px',
    },
    [theme.breakpoints.down('xs')]: {
        fontSize: '28px',
    },
}));

const Subtitle = styled(Typography)(({ theme }) => ({
    fontSize: '15px',
    fontWeight: 'bold',
    color: '#acacac',
    marginBottom: '5px',
    padding: '0 2.5px',
    fontFamily: "Poppins, sans-serif",
    lineHeight: '28px',
}));

const Paragraph = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: 300,
    color: '#acacac',
    marginBottom: '32px',
    padding: '0 2.5px',
    fontFamily: "Poppins, sans-serif",
    lineHeight: '28px',
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: 300,
    color: '#acacac',
    marginBottom: '0px',
    padding: '2.5px 16px',
    fontFamily: "Poppins, sans-serif",
    lineHeight: '28px',
}));

// component content
const PrivacyP = () => {
    return(
        <>
        <RegNavbar/>
        <Section>
            <Title variant="h2" component="h1">
                Privacy Policy
            </Title>
            <Paragraph style={{ marginBottom: '7.5px'}}>Last modified: December 17, 2020</Paragraph>
            <Subtitle>‍Introduction</Subtitle>
            <Paragraph>
            Translational Analytics and Statistics, LLC and its affiliates (“<strong>our</strong>,” “<strong>we</strong>,” or “<strong>us</strong>”) respect your privacy and are committed to protecting it through our compliance with this Privacy Policy (“<strong>Privacy Policy</strong>”). This Privacy Policy describes the types of information we may collect from you or that you may provide when you visit <Link href="/" target="_blank" rel="noopener noreferrer" style={{ color: '#212529'}}>Treatogether.com</Link> (the “<strong>Website</strong>”), and use any functionality, features or services offered on or through our Website and our practices for collecting, using, maintaining, protecting, and disclosing that information.
            </Paragraph>
            <Subtitle>‍Your Choice</Subtitle>
            <Paragraph>By accessing or using the Website, you acknowledge that you have read and understood this Privacy Policy. If you do not agree to this Privacy Policy, please discontinue your use of the Websites.
            </Paragraph>
            <Subtitle>Children Under the Age of 13</Subtitle>
            <Paragraph>We do not knowingly collect any personal information from children under 13 years of age via the Website without express parental consent. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information. If you believe we may have collected such information, please contact us as described below.
            </Paragraph>
            <Subtitle>Information We Collect About You and How We Collect It</Subtitle>
            <Box mb={2}>
            <Paragraph style={{ marginBottom: "2.5px"}}>‍Types of Information. The Website may collect several types of information from and about our users of our Website, including:
            </Paragraph>
            <List style={{ paddingBottom: '10px'}}>
                <StyledListItem>- Limited demographic information, such as your age, weight, height and sex (“demographic information”);</StyledListItem>
                <StyledListItem>- Any contact information you choose to provide us, as described on the form used to input that information (“contact information”);</StyledListItem>
                <StyledListItem>- Any information you provide regarding your stone, such as stone size, location, or side (“stone information”); and</StyledListItem>
                <StyledListItem>- Information that describes your internet connection, the equipment you use to access our Website, and details about your usage of our Website (“device information”).</StyledListItem>
            </List>
            </Box>
            <Box mb={2}>
            <Paragraph style={{ marginBottom: "2.5px"}}>These categories do not include “aggregate information”, which is data we may collect about the use of the Website or about a group or category of users which do not, or which have been modified so that they do not, identify any particular user. This Privacy Policy in no way restricts or limits our collection and use of aggregate information. We collect demographic and contact information directly from you when you provide it to us. We collect device information automatically as you navigate through the site. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, or other tracking technologies. We may also collect information about you from third parties, including but not limited to, our business partners.</Paragraph>
            </Box>
            <Box mb={2}>
            <Paragraph style={{ marginBottom: '0px'}}><i>Automatic Data Collection Technologies</i>. The technologies we use for automatic data collection may include:</Paragraph>
            <ListItem style={{ display: 'block'}}><Subtitle>‍- Cookies (or browser cookies)</Subtitle>
            <Paragraph style={{ marginBottom: '0px'}}>By accessing or using the Website, you acknowledge that you have read and understood this Privacy Policy. If you do not agree to this Privacy Policy, please discontinue your use of the Websites.
            </Paragraph></ListItem>
            <ListItem style={{ display: 'block'}}><Subtitle>‍- Locally Stored Objects</Subtitle>
            <Paragraph style={{ marginBottom: '0px'}}>By accessing or using the Website, you acknowledge that you have read and understood this Privacy Policy. If you do not agree to this Privacy Policy, please discontinue your use of the Websites.
            </Paragraph></ListItem>
            <ListItem style={{ display: 'block'}}><Subtitle>‍- Web Beacons</Subtitle>
            <Paragraph style={{ marginBottom: '0px'}}>By accessing or using the Website, you acknowledge that you have read and understood this Privacy Policy. If you do not agree to this Privacy Policy, please discontinue your use of the Websites.
            </Paragraph></ListItem>
            </Box>
            <Subtitle>How We Use Your Information</Subtitle>
            <Box mb={2}>
            <Paragraph style={{ marginBottom: "2.5px"}}>‍<i>Demographic and Contact Information</i>. We use demographic or contact information that we collect about you or that you provide to us:
            </Paragraph>
            <List style={{ paddingBottom: '10px'}}>
                <StyledListItem>- To present our Website and its features to you, including our physician recommendations.</StyledListItem>
                <StyledListItem>- To provide you with information, products, or services that you request from us or that we believe may be of value to you.</StyledListItem>
                <StyledListItem>- To fulfill any other purpose for which you provide it.</StyledListItem>
                <StyledListItem>- To allow you to participate in interactive features on our Website.</StyledListItem>
                <StyledListItem>- In any other way we may describe when you provide the information.</StyledListItem>
                <StyledListItem>- For any other purpose with your consent.</StyledListItem>
            </List>
            </Box>
            <Box mb={2}>
            <Paragraph style={{ marginBottom: "2.5px"}}><i>Demographic and Stone Information</i>. We use your demographic information, your stone information, scientific research, and real-world results to predict the likelihood of success for each of the available treatment methods with respect to your stone. Your stone information is never stored or disclosed.
            </Paragraph>
            </Box>
            <Box mb={2}>
            <Paragraph style={{ marginBottom: "2.5px"}}><i>Device Information</i>. We use your device information to deliver a better and more personalized website and services, including by enabling us to estimate our audience size and usage patterns; store information about your preferences, allowing us to customize our Website according to your individual interests; speed up your searches; or recognize you when you return to our Website. Some of that information is derived from third-party analytics providers that may track your behavior across time and across multiple websites, depending on whether you have opted out of such tracking.
            </Paragraph>
            </Box>
            <Subtitle>Disclosure of Your Information</Subtitle>
            <Box mb={2}>
            <Paragraph style={{ marginBottom: "2.5px"}}><i>Non-Identifiable Information</i>. We may disclose aggregated information or other information that does not identify you or any individual without restriction.
            </Paragraph>
            </Box>
            <Box mb={2}>
            <Paragraph style={{ marginBottom: "2.5px"}}>‍<i>Demographic or Contact Information</i>. We may disclose demographic, contact, stone, or device information that we collect or you provide as described in this Privacy Policy:
            </Paragraph>
            <List style={{ paddingBottom: '10px'}}>
                <StyledListItem>- To our subsidiaries and affiliates.</StyledListItem>
                <StyledListItem>- To contractors, service providers, and other third parties we use to support our business.</StyledListItem>
                <StyledListItem>- To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us about our Websites users is among the assets transferred.</StyledListItem>
                <StyledListItem>- To fulfill the purpose for which you provide it.</StyledListItem>
                <StyledListItem>- For any other purpose disclosed by us when you provide the information.</StyledListItem>
                <StyledListItem>- With your consent.</StyledListItem>
            </List>
            </Box>
            <Box mb={2}>
            <Paragraph style={{ marginBottom: "2.5px"}}>‍<i>Other Disclosures</i>. We may also disclose your demographic, contact, or device information:
            </Paragraph>
            <List style={{ paddingBottom: '10px'}}>
                <StyledListItem>- To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</StyledListItem>
                <StyledListItem>- If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of us, our customers, or others.</StyledListItem>
            </List>
            </Box>
            <Subtitle>Choices About How We Use and Disclose Your Information</Subtitle>
            <Box mb={2}>
            <Paragraph style={{ marginBottom: "2.5px"}}>‍We strive to provide you with choices regarding the information you provide to us. We have created mechanisms to provide you with the following control over your information:
            </Paragraph>
            </Box>
            <Box mb={2}>
            <Paragraph style={{ marginBottom: "2.5px"}}>‍<i>Tracking Technologies and Advertising</i>. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies, please note that some parts of our Website may become inaccessible or not function properly. Our first-party Cookies and Web Beacons do not track users over time or across third-party websites. Our technology does not respond differently when it receives a web browser do-not-track signal.
            </Paragraph>
            </Box>
            <Box mb={2}>
            <Paragraph style={{ marginBottom: "2.5px"}}>‍<i>Promotional Offers From Us</i>. If you do not wish for us to use your contact information to promote our own products or services, you can opt-out by clicking the applicable link in our email to you or by sending an email as described under Contact Information below. This opt-out will apply to communications regarding our transactions with you.
            </Paragraph>
            </Box>
            <Subtitle>Data Security</Subtitle>
            <Box mb={2}>
            <Paragraph style={{ marginBottom: "2.5px"}}>‍We have implemented reasonable security measures designed to secure your information from accidental loss and from unauthorized access, use, alteration, and disclosure.
            </Paragraph>
            </Box>
            <Box mb={2}>
            <Paragraph style={{ marginBottom: "2.5px"}}>‍The safety and security of your information also depends on you. We urge you to be careful about giving out information in public areas of the Website like our physician review section. The information you share in public areas may be viewed by any user of the Website.
            </Paragraph>
            </Box>
            <Box mb={2}>
            <Paragraph style={{ marginBottom: "2.5px"}}>‍Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your information, we cannot guarantee the security of your information transmitted to our website. Any transmission of information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the website.
            </Paragraph>
            </Box>
            <Subtitle>Changes to Our Privacy Policy</Subtitle>
            <Box mb={2}>
            <Paragraph style={{ marginBottom: "2.5px"}}>‍‍This Privacy Policy may change from time to time. Your continued use of the Websites after we make changes is deemed to be acceptance of those changes, so please check the Privacy Policy periodically for updates.
            </Paragraph>
            </Box>
            <Box mb={2}>
            <Paragraph style={{ marginBottom: "2.5px"}}>‍We will use best efforts to notify you of changes we make to our Privacy Policy by either posting a notice on the Website home page, by revising the Effective Date or sending you an e-mail (using the last e-mail we have on file, if any). You are responsible for periodically visiting our Website and this Privacy Policy to check for any changes.
            </Paragraph>
            </Box>
            <Subtitle>Contact Information</Subtitle>
            <Box mb={2}>
            <Paragraph style={{ marginBottom: "2.5px"}}>‍‍To ask questions or comment about this Privacy Policy and our privacy practices, contact us at: <Link href="mailto:support@trans-stat.com" target="_blank" rel="noopener noreferrer" style={{ color: '#212529'}}>support@trans-stat.com</Link>.
            </Paragraph>
            </Box>
        </Section>
        <RegFooter/>
        </>
    );
}

// export
export default PrivacyP;