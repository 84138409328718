import './App.css';
import { useState } from 'react';
import { Row, Col, Container, Form, Collapse, Spinner, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import * as C from './constants';
import ReactGA from 'react-ga';
ReactGA.initialize('G-CHMJ2B277S');

const GetDoctorsList = (data) => {
  if (data === [] || data === null || data.error) {
    return null;
  }
  return data.map(
    (one) => {
      const phone2call = "tel:" + one.phone;
      let nextwave = null;
      if(one.nextwave){
        nextwave = (<div className="nextwavedoc">
          Physician offers in-Office EWSL with nextwave<br />
          <Button variant="success" target="_blank" href="https://www.nextmed.net/nextwave">Learn more</Button>{' '}
        </div>)
      }
      return (
        <Row className="doc-row" key={one.ge_id}>
          <Col md={3}></Col>
          <Col md={2} className="doc1">Dr. {one.first_name} {one.last_name}</Col>
          <Col md={4}>
            {one.phone !== '' &&
              <span className="doc2"><a href={phone2call}>{one.phone}</a><br /></span>
            }
            <span className="doc3">{one.address}</span><br />
            <span className="doc3">{one.city}, {one.state}</span>
            <span className="doc4">{nextwave}</span>
            
            </Col>
          <Col md={3}></Col>
        </Row>
      )
    })
}

function FindPhysicians() {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [error, setError] = useState(null);
  const [zipCode, setZipCode] = useState('');
  const [physListState, setPhysListState] = useState(false);
  const [items, setItems] = useState([]);

  const InputHandler = (event) => {
    if (event.key === 'Enter' && zipCode !== '') {
      setError(null);
      setBtnDisabled(true)
      setItems(null);

      fetch(C.urlPhysiciansByZip + zipCode)
        .then(res => res.json())
        .then(
          (result) => {
            setItems(result);
            console.log(result)
            setBtnDisabled(false);
            if (result.length !== 0) {
              setPhysListState(true);
            } else {
              setPhysListState(false);
            }
          },
          (error) => {
            setError(error.message);
            setBtnDisabled(false);
            setPhysListState(false);
          })
        .catch(error => {
          setError(error.message);
          setBtnDisabled(false);
          setPhysListState(false);
        })
    }
  }

  return (
    <div>
      <Container fluid className="tt-physicians-background" id="find-physicians" style={{ paddingTop: "1rem"}}>
        <Row><Col>
          <Row>
            <Col className='tt-physicians-form'>
              <center>
                <p href="#find-a-physician" className="tt-physicians-header">Find a Physician</p>
                <p className="tt-physicians-description">We'll help you connect with a urologist near you to discuss your kidney treatment options.</p>
              </center>
            </Col>
          </Row>
          <Row style={{ marginBottom: "2rem"}}>
            <Col>
              <Form.Group className='tt-physicians-form2'>
                <Form.Label className="form-label">Zip code</Form.Label><br />
                <Form.Control className="form-input" type="text" value={zipCode}
                  onChange={event => {
                    setZipCode(event.target.value)
                  }}
                  onKeyPress={e => InputHandler(e)} />
              </Form.Group>
              <center>
                <button className="form-button"
                  disabled={btnDisabled}
                  aria-controls="physicians-list"
                  aria-expanded={physListState}
                  onClick={() => {
                    ReactGA.event({
                      category: 'Fing Physicians by Zipcode',
                      action: 'Submit',
                      label: zipCode,
                      nonInteraction: true
                    });
                    setError(null);
                    setBtnDisabled(true)
                    setItems(null);

                    fetch(C.urlPhysiciansByZip + zipCode)
                      .then(res => res.json())
                      .then(
                        (result) => {
                          setItems(result);
                          console.log(result)
                          setBtnDisabled(false);
                          if (result.length !== 0) {
                            setPhysListState(true);
                          } else {
                            setPhysListState(false);
                          }
                        },
                        (error) => {
                          setError(error.message);
                          setBtnDisabled(false);
                          setPhysListState(false);
                        })
                      .catch(error => {
                        setError(error.message);
                        setBtnDisabled(false);
                        setPhysListState(false);
                      })
                  }}>
                  {btnDisabled
                    ? <><Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    /><span>SUBMIT</span>
                    </>
                    :
                    <span>SUBMIT</span>
                  }
                </button>
                {error !== null &&
                  <div>
                    {error}<br />
                  </div>
                }
              </center>
            </Col>
          </Row>


          <Row className="found-physicians">
            <Col>
              <Collapse in={physListState}>
                <div id="physicians-list">
                  <Row className="found-physicians-head">
                    <Col style={{ textAlign: 'right' }}><div className="close-button" onClick={() => {
                      setPhysListState(false);
                    }}>X</div></Col>
                  </Row>
                  {GetDoctorsList(items)}
                </div>
              </Collapse>
            </Col></Row>
        </Col>
        </Row>
      </Container>
    </div>
  );
}

export default FindPhysicians;
