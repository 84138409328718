// @flow strict

import styled from "styled-components/macro";

const Flex = styled.span`
  ${({
    parent,
    grow,
    shrink,
    basis,
    flex,
    alignItems,
    justifyContent,
    spacing,
    direction
  }) => {
    const styles = {};

    if (parent === "inline") {
      styles.display = "inline-flex";
    } else if (parent) {
      styles.display = "flex";
    }

    if (grow !== undefined && grow !== null) {
      styles.flexGrow = grow;
    }

    if (shrink !== undefined && shrink !== null) {
      styles.flexShrink = shrink;
    }

    if (basis !== undefined && basis !== null) {
      styles.flexBasis = basis;
    }

    if (flex) {
      styles.flex = flex;
    }

    if (alignItems) {
      styles.alignItems = alignItems;
    }

    if (justifyContent) {
      styles.justifyContent = justifyContent;
    }

    if (spacing) {
      switch (direction) {
        case "column-reverse":
          styles["& > *:not(:first-child)"] = {
            marginBottom: `${spacing}px`
          };
          break;
        case "column":
          styles["& > *:not(:first-child)"] = {
            marginTop: `${spacing}px`
          };
          break;
        case "row-reverse":
          styles["& > *:not(:first-child)"] = {
            marginRight: `${spacing}px`
          };
          break;
        case "row":
        default:
          styles["& > *:not(:first-child)"] = {
            marginLeft: `${spacing}px`
          };
      }
    }

    if (direction) {
      styles.flexDirection = direction;
    }

    return styles;
  }};
`;

export default Flex;
