export const urlPhysiciansByZip = "https://tas.trans-stat.com/api/treatment/treatogether/physicians?radius=50&zip=";
export const urlGetRecomendations = " https://tas.trans-stat.com/api/stone-decision-engine/evaluator";
//export const urlPhysiciansByZip = "http://localhost:4000/api/treatment/treatogether/physicians?radius=50&zip=";

export const defaultStoneLength = 8;
export const defaultStoneWidth = 8;
export const defaultAge = 55;
export const defaultHU = 600;
export const defaultAnesthesia = "General";
export const defaultAnticoagulants = false;
export const defaultHeight = 70;
export const defaultWeight = 190;
export const defaultMachineType = { code: '487c7515-64db-4458-88ab-4b4ae39ae9d1', name: 'Storz F2' };
export const defaultStoneLocation = { name: 'MC - Mid Calyx', code: '881f846e-1158-4129-a99d-08e380e21431' };

export const therapyESWL = 0, therapyURS = 1;

export const TABLET_MAX = 1119;
export const PHONE_MAX = 767;

export const INCHES_TO_CM = 2.54;
export const LBS_TO_KG = 0.45359237;
