// imports
import React from 'react';
import { styled } from '@material-ui/core/styles';

// styled divs saved to variables
const Section = styled('div')(({ theme }) => ({
  textAlign: 'center',
  padding: '90px 0 80px',
  [theme.breakpoints.down('sm')]: {
    padding: "42px 0 24px",
  },
  [theme.breakpoints.down('xs')]: {
    padding: "24px 0 24px",
  },
}));

const Title = styled('h2')(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 500,
  marginBottom: '3rem',
  fontFamily: "Poppins, sans-serif",
  padding: '0 32px',
  [theme.breakpoints.down('sm')]: {
   fontSize: '34px',
  },
  [theme.breakpoints.down('xs')]: {
   fontSize: '32px',
   marginTop: '20px'
  },
}));

const VideoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '20px',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    padding: '0 10px'
  },
  [theme.breakpoints.down('sm')]: {
   flexDirection: 'column',
   alignItems: 'center',
  },
}));

const IframeContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  maxWidth: '720px',
  paddingTop: '24%',
  [theme.breakpoints.down('lg')]: {
    width: '80%',
    paddingTop: '28%',
  },
  [theme.breakpoints.down('md')]: {
    paddingTop: '30%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '85%',
    paddingTop: '42%',
  },
  [theme.breakpoints.down('xs')]: {
    width: '95%',
    paddingTop: '56.25%',
    justifySelf: "center",
  },
}));

const StyledIframe = styled('iframe')({
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  border: '0',
});

// component content
const PatientStories = () => {
  return (
    <Section id="patient-stories">
      <Title>Patient Stories</Title>
      <VideoWrapper>
        <IframeContainer>
          <StyledIframe src="https://www.youtube.com/embed/6Oe_vzaRdYM" title="Treatogether Patient Story: Joan" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></StyledIframe>
        </IframeContainer>
        <IframeContainer>
          <StyledIframe src="https://www.youtube.com/embed/sjyTu40bRYM" title="Treatogether Patient Story: Glenn" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></StyledIframe>
        </IframeContainer>
      </VideoWrapper>
    </Section>
  );
}

// export
export default PatientStories;
