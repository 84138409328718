import * as React from "react";
import { FormHelperText, FormControl } from "@material-ui/core";
import {
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup as BaseToggleButtonGroup
} from "@material-ui/lab";
import type { ToggleButton as ToggleButtonType } from "@material-ui/lab";

import { connectInput } from "../Form";
import { InputApiType } from "../Form/sharedTypes";

type ToggleButtonPropsType = {
  children?: React.Node,
  disabled?: boolean,
  disableFocusRipple?: boolean,
  disableRipple?: boolean,
  selected?: boolean,
  value?: any,
  style?: Object,
  onChange?: Function
};

const ToggleButton = ({
  children,
  disabled,
  disableFocusRipple,
  disableRipple,
  selected,
  value,
  style,
  onChange
}: ToggleButtonPropsType): ToggleButtonType => (
  <MuiToggleButton
    value={value}
    selected={selected}
    disabled={disabled}
    disableFocusRipple={disableFocusRipple}
    disableRipple={disableRipple}
    onChange={onChange}
  >
    {children}
  </MuiToggleButton>
);

ToggleButton.defaultProps = {
  children: undefined,
  disabled: false,
  disableFocusRipple: false,
  disableRipple: false,
  selected: undefined,
  value: undefined,
  onChange: undefined,
  style: {}
};

type ToggleButtonGroupPropsType = {
  error?: React.Node,
  classes: {
    selected?: string,
    root: string
  },
  children?: node,
  exclusive?: boolean,
  value?: ?string,
  selected?: boolean,
  onChange?: Function,
  style?: Object
};

const ToggleButtonGroup = ({
  error,
  classes,
  children,
  exclusive,
  value,
  selected,
  onChange,
  style
}: ToggleButtonGroupPropsType) => (
  <FormControl error={Boolean(error)} style={style}>
    <BaseToggleButtonGroup
      value={value}
      selected={selected}
      onChange={onChange}
      exclusive={exclusive}
    >
      {children}
    </BaseToggleButtonGroup>
    {error ? <FormHelperText>{error}</FormHelperText> : undefined}
  </FormControl>
);

ToggleButtonGroup.defaultProps = {
  error: undefined,
  children: undefined,
  value: 0,
  selected: false,
  exclusive: true,
  onChange: () => {},
  style: undefined
};

type FormToggleButtonGroupBasePropsType = {
  ...ToggleButtonGroupPropsType,
  formApi: InputApiType,
  required?: boolean
};

const FormToggleButtonGroupBase = ({
  formApi,
  required,
  ...rest
}: FormToggleButtonGroupBasePropsType) => {
  const { value = "", onChange, error } = formApi;

  return (
    <ToggleButtonGroup
      {...rest}
      value={value}
      onChange={values => {
        if ((required && values !== null) || !required) {
          onChange(values);
        }
      }}
      error={error}
    />
  );
};

FormToggleButtonGroupBase.defaultProps = {
  required: false
};

const FormToggleButtonGroup = connectInput(FormToggleButtonGroupBase, {
  defaultName: "togglebuttongroup",
  bundleApi: true
});

export default ToggleButton;
export { ToggleButtonGroup, FormToggleButtonGroup };
