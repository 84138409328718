// imports
import React from 'react';
import { AppBar, Toolbar, IconButton, Button } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import Logo from '../../images/TtLogoW.png';

// styled divs saved to variables
const StyledAppBar = styled(AppBar)({
  background: "#5aaf5e",
  width: "100%",
  alignItems: "center",
  boxShadow: "none",
  padding: '0 1%',
});

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignSelf: "center",
  width: "98%",
  maxWidth: "1040px",
  padding: "0 32px",
  [theme.breakpoints.down('md')]: {
    padding: "0",
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  paddingLeft: "0",
  '&:hover': {
    backgroundColor: "transparent",
  },
  [theme.breakpoints.down('md')]: {
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: "6px",
    paddingBottom: "8px",
  },
}));

const LogoImage = styled('img')(({ theme }) => ({
  height: '50px',
  marginTop: '6px',
  position: "static",
  verticalAlign: 'middle',
  [theme.breakpoints.down('md')]: {
   height: '48px',
  },
  [theme.breakpoints.down('sm')]: {
   height: '45px',
  },
  [theme.breakpoints.down('xs')]: {
    height: '40px',
  },
}));

const NavContainer = styled('div')({
  display: 'flex',
});

const StyledButton = styled(Button)(({ theme }) => ({
  color: "white",
  letterSpacing: "1px",
  fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  fontSize: "12px",
  paddingRight: "24px",
  paddingLeft: "24px",
  fontWeight: '500',
  [theme.breakpoints.down('xs')]: {
    paddingRight: "12px",
    fontSize: "11px"
  },
}));


// component content
const RegNavbar = () => {
  return (
    <StyledAppBar>
      <StyledToolbar>
        <NavContainer>
        <StyledIconButton color="inherit" aria-label="logo" href="/">
        <LogoImage src={Logo} alt="logo" />
        </StyledIconButton>
        </NavContainer>
        <NavContainer>
        <StyledButton color="inherit" href="/">GO BACK</StyledButton>
        </NavContainer>      
      </StyledToolbar>
    </StyledAppBar>
  );
}

// export
export default RegNavbar;
