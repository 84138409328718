// @flow strict

// Libraries
import * as React from "react";

// Relative Imports
import { FormConsumer } from "./context";
import { flattenValues, flattenErrors } from "./utils";
import type { FormStateType, FormSectionApiType } from "./sharedTypes";

function formSectionApi(formState: FormStateType): FormSectionApiType {
  const {
    values,
    errors,
    sectionRegister,
    setInputValue,
    setInputError,
    submit,
    valuesHaveChanged
  } = formState;

  return {
    values,
    errors,
    sectionRegister,
    flatValues: flattenValues(sectionRegister, values),
    flatErrors: flattenErrors(sectionRegister, errors),
    onChange: (value, options) => setInputValue([], value, options),
    onChangeInput: setInputValue,
    setError: (error, { afterUpdate } = {}) =>
      setInputError([], error, { afterUpdate }),
    setInputError,
    submit,
    valuesHaveChanged
  };
}

const FormApiConnector = ({
  children: renderFn
}: {
  children: FormSectionApiType => React.Node
}): React.Node => (
  <FormConsumer>
    {formState => renderFn(formSectionApi(formState))}
  </FormConsumer>
);

export default FormApiConnector;
