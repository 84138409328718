// importing necessary modules
import React, { Component } from "react";
import Media from "react-media";
//import style
import './App.css';
//import components
import {
  Flex,
  ThemingWrapper,
  Form,
  Popover,
  Radio,
  FormRadioGroup,
  FormTextbox,
  Textbox,
  ToggleButton,
  FormToggleButtonGroup,
  LoadingIndicator,
  Navbar,
  Hero,
  About,
  Treatments,
  PatientStories,
  FooterSection
} from "./components/index";
import FindPhysicians from './FindPhysicians.js'
// import styles
import { TABLET_MAX } from "./constants";
import {
  AppContentWrapper,
  AppWrapper,
  Title,
  TitleSection,
  Circle,
  Container1,
  FormContainer,
  ResultsContainer1,
  Label,
  Title1,
  FormRow,
  SubTitle1,
  Divider,
  Recommend,
  Message,
  Info,
  Result,
  ResponsiveFlex,
  ButtonsFlex,
  StyledButton
} from "./styled";
//import additional material UI components
import { FormControlLabel, Select, MenuItem, TextField, InputAdornment } from '@material-ui/core';

import {
  decimalTypingValidation,
  numberTypingValidation
} from "./components/validators";

// define keys that can be typed in a decimal and whole number input field
const WHOLE_NUMBER_KEYS = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "Backspace",
  "ArrowLeft",
  "ArrowRight",
  "Tab"
];
const DECIMAL_NUMBER_KEYS = [...WHOLE_NUMBER_KEYS, "."];
// defines unit coversions for BMI calculation
const INCHES_TO_CM = 2.54;
const LBS_TO_KG = 0.45359237;

// preventing keypresses of charaters that arenot allowed
function preventKeys(event, keys) {
  if (
    keys.indexOf(event.key) === -1 ||
    (event.key === "." && event.currentTarget.value.includes("."))
  )
    event.preventDefault();
}

// function to calculate BMI
function calculateBMI(weight, height, weightUnits, heightUnits) {
  const m = (heightUnits === "in" ? height * INCHES_TO_CM : height) / 100;
  const kg = weightUnits === "lbs" ? weight * LBS_TO_KG : weight;
  return Math.round((kg / m ** 2) * 10) / 10;
}
// Main "StoneLogik" component 
class StoneLogik extends Component {
  constructor(props) {
    super(props);
    // initialising the state
    this.state = {
        age: "",
        gender: "",
        height: "",
        heightUnits: "",
        weight: "",
        weightUnits: "",
        bmi: "",
        medCondition:"",
        length: "",
        width: "",
        stoneLocation: "",
        stoneSide: "",
        anticoagulants: "",
        SWLMachine: "00001",
        LaserMachine: "000010",
        isFormValid: false,
        showCalculations: false,
        fetchedData: null,
        isLoading: false,
        calculationsDone: false
  };
  // binds calculate button to the state
    this.handleCalculate = this.handleCalculate.bind(this);
    let heightUnit;
    let weightUnit;

    this.prepopulated = {
      heightUnit,
      weightUnit,
    };
  };
// handles changes to the form fields
  handleChange = (e) => {
    const {name, value} = e.target;
    this.setState({
      [name]: value,
    });
  };
// handles change to the gender radio group
  handleGenderChange = (e) => {
    this.setState({ gender: parseInt(e.target.value, 10) });
  };
// handles change to the medical condition radio group
  handleMedConditionChange = (e) => {
    this.setState({ medCondition: parseInt(e.target.value, 10) });
  };
// handles change to the stone side radio group
  handleStoneSideChange = (e) => {
    this.setState({ stoneSide: parseInt(e.target.value, 10) });
  };
// handles change to the anticoagulants radio group
  handleAnticoagulantsChange = (e) => {
    this.setState({ anticoagulants: parseInt(e.target.value, 10) });
  };
// function to validate form
  validateForm = () => {
    const { age, gender, bmi, medCondition, length, width, stoneLocation, SWLMachine, LaserMachine, stoneSide, anticoagulants } = this.state;

    // Check if input fields have truthy values and are not negative
    const inputsValid = [age, bmi, length, width, stoneLocation, SWLMachine, LaserMachine].every(item => item > 0);

    // Check if boolean fields have truthy values or are explicitly 0
    const booleansValid = [gender, medCondition, stoneSide, anticoagulants].every(item => item || item === 0);

    // Update isFormValid in the state if both checks pass
    const isFormValid = inputsValid && booleansValid;
    return isFormValid;
};
  // handles calculation logic
  handleCalculate = async (e) => {
    e.preventDefault();
    // validate form
    const isFormValid = this.validateForm(); // check if form is valid and store result
    if (!isFormValid) {
    alert("Please fill out all the required fields correctly before proceeding.");
    return; // return early if form is not valid
    }
    //update the state to show calculations and "loading"
    this.setState({
      showCalculations: true,
      isLoading: true
    }, 
    async () => {
      console.log(this.state);
    // prep the form data by extracting the values from the state and converting them to numbers
      let preparedData = [
        this.state.age,
        this.state.gender,
        this.state.bmi,
        this.state.medCondition,
        this.state.length,
        this.state.width,
        ...this.state.stoneLocation,
        this.state.stoneSide,
        this.state.anticoagulants,
        ...this.state.SWLMachine,
        ...this.state.LaserMachine
      ].map(item => Number(item));
  
      // Attempt to process data
      try {
        const response = await fetch(`https://sde.treatogether.com/api/process-data`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ data: preparedData }),
        });
  
        const data = await response.json();
        //console.log(data); // should print the processed data
  
        this.setState({
          fetchedData: data, // store fetched data in state
          calculationsDone: true, //Set calulationsDone to true (disable Calculations button)
          isLoading: false // set loading state to false after data fetch is complete
        }, () => {
          const element = document.getElementById('resultsContainer');
          const offset = -70;
          const position = element.getBoundingClientRect().top + window.pageYOffset + offset;
          window.scrollTo({ top: position, behavior: 'smooth' });
        });
  
      } catch (error) {
        this.setState({ isLoading: false }); // set loading to false in case of error
        console.error('Error:', error);
      }
    });
  };
  
// function to set BMI value in the state based on height & weight inputs
  setBMI = (weight, height, weightUnits, heightUnits) => {
    const { bmi } = this.state;
    if (weight && height && weightUnits && heightUnits) {
      //calculate the BMI and update the state
      const result = calculateBMI(weight, height, weightUnits, heightUnits);
      this.setState({ bmi: result });
    } else if (bmi) {
      //clear the BMI value if the height/weight inputs are missing
      this.setState({ bmi: "" });
    }
  };

  //set the message response based on the "recommendation"
  getRecommendation() {
    let response = {
      recommendation: '',
      message: '',
      additionalInfo: ''
    }; 
    switch (this.state.fetchedData?.Recommendation) {
      case 'SWL':
        response.recommendation= "Try SWL"
        response.message= "Based on your stone's characteristics and other determining factors, our engine has predicted Shock Wave Lithotripsy (SWL) may be your best treatment option. SWL is a non-invasive procedure with minimal recovery time and an overall high success rate. Be sure to learn about the benefits and costs of this treatment option and talk to your urologist about possible SWL solutions."
        response.additionalInfo = "By utilizing our state-of-the-art Stone Decision Engine (SDE) which is a compilation of data from thousands of previous stone treatments, we are able to pinpoint suitable treatment choices customized to your specific needs. We strongly advise seeking guidance from a medical expert for a comprehensive assessment.";
        break;
      case 'URS':
        response.recommendation= "Try URS"
        response.message= "Based on your stone’s characteristics and other determining factors, our engine has predicted Ureteroscopy (URS) may be your best treatment option. URS is a minimally invasive procedure with a 5-to-7-day typical recovery period and consistently high success rates. Be sure to learn about the benefits and costs of this treatment option and talk to your urologist about possible URS solutions.";
        response.additionalInfo= "By utilizing our state-of-the-art Stone Decision Engine (SDE) which is a compilation of data from thousands of previous stone treatments, we are able to pinpoint suitable treatment choices customized to your specific needs. We strongly advise seeking guidance from a medical expert for a comprehensive assessment.";
        break;
      case 'SWL_or_URS':
        response.recommendation= "Try SWL or URS"
        response.message= "Based on your stone's characteristics and other determining factors, our engine has predicted that both Shock Wave Lithotripsy (SWL) and Ureteroscopy (URS) are suitable treatment options. SWL is a non-invasive procedure with minimal recovery time and an overall high success rate. URS is a minimally invasive procedure with a 5–7-day typical recovery period and consistently high success rates. Be sure to learn about the benefits and costs of both treatment options and talk to your urologist about possible solutions.";
        response.additionalInfo= "By utilizing our state-of-the-art Stone Decision Engine (SDE) which is a compilation of data from thousands of previous stone treatments, we are able to pinpoint suitable treatment choices customized to your specific needs. We strongly advise seeking guidance from a medical expert for a comprehensive assessment.";
        break;
      case 'Uncertain':
        response.recommendation= "Uncertain"
        response.message= "Our AI models' scores were too uncertain to make predictions. We are unable to make a recommendation at this time.";
        response.additionalInfo= "A medical professional can provide personalized advice based on your specific condition. Be sure to learn about the benefits and costs of both treatment options (SWL and URS) and talk to your urologist about possible solutions.";
        break;
      default:
        response.recommendation= "Data Unavailable";
        response.message= "It seems we are having trouble gathering the data. We suggest consulting with a medical professional for the best advice.";
        response.additionalInfo= "A medical professional can provide personalized advice based on your specific condition. Be sure to learn about the benefits and costs of both treatment options (SWL and URS) and talk to your urologist about possible solutions.";
    }
    return response;
  };

// render method fro application UI
  render() {
    const {
      popoverOpen
    } = this.state;
    const {
      heightUnit,
      weightUnit
    } = this.prepopulated;
    let results = this.getRecommendation();
    // return the application UI (jsx)
    return (
          <div className="App">
            <Navbar/>
            <Hero/>
            <About/>
            <Treatments/>
            <ThemingWrapper>
          <AppWrapper id="try-treatogether">
            <TitleSection>
              <Title>Try Treatogether</Title>
            </TitleSection>
            <AppContentWrapper>
              <Media query={`(min-width: ${TABLET_MAX + 1}px)`}>
                {isDesktopSize => (
                  <Flex direction="column">
                    <Container1>
                      <Form
                      defaultValues={{
                        weightUnits: weightUnit || "lbs",
                        heightUnits: heightUnit || "in",
                      }}
                      >
                      {formApi => {
                      const { values } = formApi;
                      const { weight, height, weightUnits, heightUnits } = values;
                      return (
                      <span>
                        <Flex
                        direction="column"
                        justifyContent="space-evenly"
                        parent
                      >
                      <Title1>Basic Demographics</Title1>
                      <SubTitle1>
                        These demographics will help the AI algorithm tailor its
                        predictions to you.
                      </SubTitle1>
                      <Divider />
                    <FormContainer>
                      <FormRow>
                        <Label bold>Age</Label>
                        <br />
                        <TextField
                          name="age"
                          type="number"
                          className="ageInput"
                          min={0}
                          validate={numberTypingValidation}
                          onKeyDown={event =>
                            preventKeys(event, WHOLE_NUMBER_KEYS)
                          }
                          value={this.state.age}
                          style={{ background: 'white', borderRadius: 5 }}
                          onChange={this.handleChange}
                        />
                      </FormRow>
                      <FormRow>
                        <Label>Gender</Label>
                        <br />
                        <FormRadioGroup row>
                          <FormControlLabel
                            style={{ marginRight: 20, color: 'white', fontWeight: 'bold' }}
                            label="Male"
                            control={<Radio name="gender" value="1" onChange={this.handleGenderChange}/>}
                          />
                          <FormControlLabel
                          style={{ color: 'white' }}
                            label="Female"
                            control={<Radio name="gender" value="0" onChange={this.handleGenderChange}/>}
                          />
                        </FormRadioGroup>
                      </FormRow>
                      <FormRow>
                        <Label bold>Height (in)</Label>
                        <br />
                        <Flex>
                          <FormTextbox
                            name="height"
                            type="number"
                            min={0}
                            validate={decimalTypingValidation}
                            onKeyDown={event =>
                              preventKeys(event, DECIMAL_NUMBER_KEYS)
                            }
                            onBlur={() => {
                              this.setBMI(
                                weight,
                                height,
                                weightUnits,
                                heightUnits
                              );
                            }}
                            style={{ width: '90%', height: 15, marginRight: 10 }}
                            value={this.state.height} 
                            onChange={this.handleChange}
                          />
                          <FormToggleButtonGroup
                            name="heightUnits"
                            required
                            style={{ width: 86, height: 44, marginBottom: 0, borderRadius: 5, background: 'white', display: 'none' }}
                          >
                            <ToggleButton name="in" value="in">
                              in
                            </ToggleButton>
                            <ToggleButton name="cm" value="cm">
                              cm
                            </ToggleButton>
                          </FormToggleButtonGroup>
                        </Flex>
                      </FormRow>
                      <FormRow>
                        <Label bold>Weight (lbs)</Label>
                        <br />
                        <Flex>
                          <FormTextbox
                            name="weight"
                            type="number"
                            min={0}
                            validate={decimalTypingValidation}
                            onKeyDown={event =>
                              preventKeys(event, DECIMAL_NUMBER_KEYS)
                            }
                            onBlur={() => {
                              this.setBMI(
                                weight,
                                height,
                                weightUnits,
                                heightUnits
                              );
                            }}
                            style={{ width: '90%', height: 15, marginRight: 10 }}
                            value={this.state.weight}
                            onChange={this.handleChange}
                          />
                          <FormToggleButtonGroup
                            name="weightUnits"
                            required
                            style={{ width: 86, height: 44, marginBottom: 0, borderRadius: 5, background: 'white', display: 'none' }}
                          >
                            <ToggleButton name="lbs" value="lbs">
                              lbs
                            </ToggleButton>
                            <ToggleButton name="kg" value="kg">
                              kg
                            </ToggleButton>
                          </FormToggleButtonGroup>
                        </Flex>
                        <Flex style={{display: "none"}}>
                          <Label>BMI:</Label>
                          <Textbox
                            name="bmi"
                            style={{ width: 75, height: 10 }}
                            value={this.state.bmi}
                            disabled
                          />
                          <Popover
                            open={popoverOpen}
                            anchorElement={
                              <Circle
                                aria-owns={
                                  popoverOpen ? "mouse-over-popover" : null
                                }
                                aria-haspopup="true"
                                onMouseEnter={() =>
                                  this.setState({ popoverOpen: true })
                                }
                              >
                                i
                              </Circle>
                            }
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "center"
                            }}
                            transformOrigin={{ vertical: "bottom", horizontal: "center" }}
                            hover
                          >
                            Your Body Mass Index (BMI) is automatically calculated. Just enter your height and weight, and we'll do the rest!
                          </Popover>
                        </Flex>
                      </FormRow>
                      <FormRow>
                        <Label bold>Medical conditions
                        <Popover
                            open={popoverOpen}
                            anchorElement={
                              <Circle
                                aria-owns={
                                  popoverOpen ? "mouse-over-popover" : null
                                }
                                aria-haspopup="true"
                                onMouseEnter={() =>
                                  this.setState({ popoverOpen: true })
                                }
                              >
                                i
                              </Circle>
                            }
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "center"
                            }}
                            transformOrigin={{ vertical: "bottom", horizontal: "center" }}
                            hover
                          >
                            Please select 'Yes' if you have any known medical conditions. This includes conditions like diabetes, high blood pressure, heart disease, etc. If you're unsure, it's always best to consult with your healthcare provider.
                          </Popover>
                          </Label>
                        <br />
                        <FormRadioGroup row>
                          <FormControlLabel
                            style={{ marginRight: 20, color:'white' }}
                            label="Yes"
                            control={<Radio name="medCondition" value="1" onChange={this.handleMedConditionChange}/>}
                          />
                          <FormControlLabel
                            style={{ color: 'white' }}
                            label="No"
                            control={<Radio name="medCondition" value="0" onChange={this.handleMedConditionChange}/>}
                          />
                        </FormRadioGroup>
                      </FormRow>
                      </FormContainer>
                      <Title1>Treatment Information</Title1>
                      <SubTitle1>
                        Stone characteristics help the AI algorithm determine probabilities of treatment success.
                      </SubTitle1>
                      <Divider />
                      <FormContainer>
                      <FormRow>
                        <Label bold>Stone size
                        <Popover
                            open={popoverOpen}
                            anchorElement={
                              <Circle
                                aria-owns={
                                  popoverOpen ? "mouse-over-popover" : null
                                }
                                aria-haspopup="true"
                                onMouseEnter={() =>
                                  this.setState({ popoverOpen: true })
                                }
                              >
                                i
                              </Circle>
                            }
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "center"
                            }}
                            transformOrigin={{ vertical: "bottom", horizontal: "center" }}
                            hover
                          >
                            If you're unsure about the exact dimensions of your stone, no worries! Just enter the same measurement for both the length and width inputs. This will help us provide the most accurate treatment recommendations.
                          </Popover>
                        </Label>
                        <br />
                        <Flex direction="row" parent="inline">
                          <Flex
                            direction="column"
                            alignItems="flex-start"
                            parent
                          >
                            <TextField
                              name="length"
                              type="number"
                              className="stoneInput"
                              min={0}
                              validate={decimalTypingValidation}
                              onKeyDown={event =>
                                preventKeys(event, DECIMAL_NUMBER_KEYS)
                              }
                              style={{ background: 'white', borderRadius: 5, fontSize: '16px' }}
                              value={this.state.length}
                              onChange={this.handleChange}
                              placeholder="Length"
                              InputProps={{
                                endAdornment: <InputAdornment position="end" style={{ paddingLeft: "none"}}>mm</InputAdornment>,
                              }}
                            />
                          </Flex>
                          <Flex
                            direction="column"
                            alignItems="flex-start"
                            parent
                          >
                            <TextField
                              name="width"
                              type="number"
                              className="stoneInput"
                              min={0}
                              validate={decimalTypingValidation}
                              onKeyDown={event =>
                                preventKeys(event, DECIMAL_NUMBER_KEYS)
                              }
                              style={{ background: 'white', borderRadius: 5}}
                              endAdornment="mm"
                              value={this.state.width}
                              onChange={this.handleChange}
                              placeholder="Width"
                              InputProps={{
                                endAdornment: <InputAdornment position="end" style={{ paddingLeft: "none"}}>mm</InputAdornment>,
                              }}
                            />
                          </Flex>
                        </Flex>
                      </FormRow>
                      <FormRow>
                        <Label bold>Stone location</Label>
                        <br />
                        <Select
                          name="stoneLocation"
                          className="dropdown"
                          value={this.state.stoneLocation}
                          onChange={this.handleChange}
                          placeholder="Select"
                          style={{
                            textAlign: "start",
                            background: 'white',
                          }}
                        >
                          <MenuItem value="100"> K - Kidney </MenuItem>
                          <MenuItem value="010"> U - Ureter </MenuItem>
                          <MenuItem value="001"> O - Other </MenuItem>
                        </Select>
                      </FormRow>
                      <FormRow>
                        <Label marginTop="25px" bold>
                          Stone side
                        </Label>
                        <br />
                        <FormRadioGroup row>
                          <FormControlLabel
                            style={{ marginRight: 16, color: 'white' }}
                            label="Left"
                            control={<Radio name="stoneSide" value="0" onChange={this.handleStoneSideChange}/>}
                          />
                          <FormControlLabel
                            style={{ color: 'white' }}
                            label="Right"
                            control={<Radio name="stoneSide" value="1" onChange={this.handleStoneSideChange}/>}
                          />
                        </FormRadioGroup>
                      </FormRow>
                      <FormRow>
                        <Label bold>Anticoagulants
                        <Popover
                            open={popoverOpen}
                            anchorElement={
                              <Circle
                                aria-owns={
                                  popoverOpen ? "mouse-over-popover" : null
                                }
                                aria-haspopup="true"
                                onMouseEnter={() =>
                                  this.setState({ popoverOpen: true })
                                }
                              >
                                i
                              </Circle>
                            }
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "center"
                            }}
                            transformOrigin={{ vertical: "bottom", horizontal: "center" }}
                            hover
                          >
                            If you'll be taking any blood thinners prior to your treatment, please select 'Yes'. Anticoagulants are usually considered by doctors up to a week before a procedure. If you're unsure, please consult with your healthcare provider.
                          </Popover>
                        </Label>
                        <br />
                        <FormRadioGroup row>
                          <FormControlLabel
                            style={{ marginRight: 20, color: 'white' }}
                            label="Yes"
                            control={<Radio name="anticoagulants" value="1" onChange={this.handleAnticoagulantsChange}/>}
                          />
                          <FormControlLabel
                            style={{ color: 'white' }}
                            label="No"
                            control={<Radio name="anticoagulants" value="0" onChange={this.handleAnticoagulantsChange}/>}
                          />
                        </FormRadioGroup>
                      </FormRow>
                      <FormRow style={{ display: "none"}}>
                        <Label bold>SWL Machine
                          <Popover
                            open={popoverOpen}
                            anchorElement={
                              <Circle
                                aria-owns={
                                  popoverOpen ? "mouse-over-popover" : null
                                }
                                aria-haspopup="true"
                                onMouseEnter={() =>
                                  this.setState({ popoverOpen: true })
                                }
                              >
                                i
                              </Circle>
                            }
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "center"
                            }}
                            transformOrigin={{ vertical: "bottom", horizontal: "center" }}
                            hover
                          >
                            If you know which Shock Wave Lithotripsy (SWL) machine will be used for your procedure, please select it from the dropdown. If you're unsure, just leave the default machine selected for the most accurate results.
                          </Popover>
                        </Label>
                        <br />
                        <Select
                          name="SWLMachine"
                          value={this.state.SWLMachine}
                          onChange={this.handleChange}
                          placeholder="Select"
                          style={{
                            width: 285,
                            textAlign: "start",
                            height: 36,
                            background: 'white'
                          }}
                        >
                          <MenuItem value="10000">
                            Dornier Compact Delta II
                          </MenuItem>
                          <MenuItem value="01000">
                            Dornier Compact Delta III
                          </MenuItem>
                          <MenuItem value="00100">
                            Dornier Compact Sigma
                          </MenuItem>
                          <MenuItem value="00010">Storz F2</MenuItem>
                          <MenuItem value="00001">Storz SLX-T</MenuItem>
                        </Select>
                      </FormRow>
                      <FormRow style={{ display: "none"}}>
                        <Label bold>Laser Machine
                        <Popover
                            open={popoverOpen}
                            anchorElement={
                              <Circle
                                aria-owns={
                                  popoverOpen ? "mouse-over-popover" : null
                                }
                                aria-haspopup="true"
                                onMouseEnter={() =>
                                  this.setState({ popoverOpen: true })
                                }
                              >
                                i
                              </Circle>
                            }
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "center"
                            }}
                            transformOrigin={{ vertical: "bottom", horizontal: "center" }}
                            hover
                          >
                            If you know which Laser machine will be used for your procedure, please select it from the dropdown. If you're unsure, just leave the default machine selected for the most accurate results.
                          </Popover>
                        </Label>
                        <br />
                        <Select
                          name="LaserMachine"
                          placeholder="Select"
                          style={{
                            width: 285,
                            textAlign: "start",
                            height: 36,
                            background: 'white'
                          }}
                          value={this.state.LaserMachine}
                          onChange={this.handleChange}
                        >
                          <MenuItem value="100000">
                            Dornier Medilas H20
                          </MenuItem>
                          <MenuItem value="010000">
                            Dornier Medilas H30
                          </MenuItem>
                          <MenuItem value="001000">
                            Dornier Medilas H35
                          </MenuItem>
                          <MenuItem value="000100">
                            Lumenis Versapulse 100 watt
                          </MenuItem>
                          <MenuItem value="000010">
                            Lumenis Versapulse 20 watt
                          </MenuItem>
                          <MenuItem value="000001">
                            Odyssey Convergent 30 watt
                          </MenuItem>
                        </Select>
                      </FormRow>
                      <FormRow style={{ marginTop: "2rem"}}>
                      <ResponsiveFlex>
                        <ButtonsFlex style={{ textAlign: "center"}}>
                        <StyledButton
                          type="button"
                          color="secondaryRipple"
                          onClick={() => window.location.reload()}
                        >
                          CLEAR FORM
                        </StyledButton>
                        </ButtonsFlex>
                        <ButtonsFlex style={{ textAlign: "center"}}>
                        <StyledButton
                          type="button"
                          color="secondaryRipple"
                          disabled={this.state.calculationsDone }
                          id="calculate"
                          onClick={this.handleCalculate}
                          fullWidth
                        >
                          SEE MY RESULTS
                        </StyledButton>
                        </ButtonsFlex>
                        </ResponsiveFlex>
                      </FormRow>
                      </FormContainer>
                      </Flex>
                      </span>
                      );
                      }}
                      </Form>
                    </Container1>
                  </Flex>
                )}
              </Media>
            </AppContentWrapper>
          </AppWrapper>
          {this.state.isLoading ? (
              <LoadingIndicator/>
          ) : this.state.showCalculations ? (
          <>
            <ResultsContainer1 id="resultsContainer">
            <FormRow>
              <Result>Your Results</Result>
              <br></br>
              <Recommend>{results.recommendation}</Recommend>
              <br></br>
              <Message>{results.message}</Message>
              <br></br>
              <Info>{results.additionalInfo}</Info>
            </FormRow>
            </ResultsContainer1>
          </>
          ) : null}
        </ThemingWrapper>
            <FindPhysicians/>
            <PatientStories/>
            <FooterSection/>
          </div>
    );
  }
}
// exporting the App component
export default StoneLogik;
