const isWholeNumber = string => /^\d*$/.test(string);
const isDecimal = string => /^\d*(\.\d?)?$/.test(string);

const decimalTypingValidation = (confirmChange, newValue) => {
  if (isDecimal(newValue) || newValue === "") {
    confirmChange(newValue);
  }
};

const numberTypingValidation = (confirmChange, newValue) => {
  if (isWholeNumber(newValue) || newValue === "") {
    confirmChange(newValue);
  }
};

export { decimalTypingValidation, numberTypingValidation };
