// @flow strict

// Libraries
import * as React from "react";
import { withStyles, Button as MuiButton } from "@material-ui/core";

// Library Types
import type { Button as ButtonType } from "@material-ui/core";

// Relative Imports
import { connectInput } from "../Form";
import type { InputApiType } from "../Form/sharedTypes";

type ButtonStylesPropsType = {
  custom?: {
    button?: {
      colors?: {
        flatPrimary?: {},
        flatSecondary?: {},
        flatConfirm?: {},
        flatWarning?: {},
        flatDanger?: {},
        flatDisabled?: {},
        secondaryFlatDisabled?: {},
        primaryRipple?: {},
        confirmRipple?: {},
        warningRipple?: {},
        dangerRipple?: {}
      }
    }
  }
};

type Color = "primary" | "secondary" | "confirm" | "warning" | "danger";

const buttonStyles = (props: ButtonStylesPropsType = {}): {} => {
  const { custom = {} } = props;
  const { button = {} } = custom;
  const { colors = {} } = button;
  const {
    flatPrimary,
    flatSecondary,
    flatConfirm,
    flatWarning,
    flatDanger,
    flatDisabled,
    secondaryFlatDisabled,
    primaryRipple,
    confirmRipple,
    warningRipple,
    dangerRipple
  } = colors;

  return {
    primary: flatPrimary,
    secondary: flatSecondary,
    confirm: flatConfirm,
    warning: flatWarning,
    danger: flatDanger,
    disabled: flatDisabled,
    secondaryDisabled: secondaryFlatDisabled,
    primaryRipple,
    confirmRipple,
    warningRipple,
    dangerRipple
  };
};

type ButtonPropsType = {
  onClick?: (?SyntheticEvent<HTMLButtonElement>) => void,
  children?: React.Node,
  color?: Color,
  disabled?: boolean,
  fullWidth?: boolean,
  className?: string,
  classes: {
    primary?: string,
    secondary?: string,
    confirm?: string,
    warning?: string,
    danger?: string,
    disabled?: string,
    secondaryDisabled?: string,
    primaryRipple?: string,
    confirmRipple?: string,
    warningRipple?: string,
    dangerRipple?: string
  },
  href?: string,
  size?: "small" | "medium" | "large",
  style?: Object
};

const ButtonBase = ({
  onClick,
  children,
  color,
  disabled,
  fullWidth,
  classes,
  className,
  href,
  size,
  style
}: ButtonPropsType): ButtonType => {
  let rootClass: ?string = classes.primary;
  let rippleClass: ?string = classes.primaryRipple;

  if (color) {
    rootClass = classes[color];
    rippleClass = classes[`${color}Ripple`];
  }

  return (
    <MuiButton
      onClick={onClick}
      disabled={disabled}
      size={size}
      fullWidth={fullWidth}
      href={href}
      style={style}
      classes={{
        root: rootClass,
        disabled:
          color === "secondary" ? classes.secondaryDisabled : classes.disabled
      }}
      className={className}
      TouchRippleProps={{ classes: { child: rippleClass } }}
    >
      {children}
    </MuiButton>
  );
};

ButtonBase.defaultProps = {
  onClick: undefined,
  children: undefined,
  color: "primary",
  className: "",
  disabled: false,
  fullWidth: undefined,
  href: undefined,
  size: "medium",
  style: undefined
};

const Button = withStyles(buttonStyles)(ButtonBase);

type SubmitButtonBasePropsType = {
  ...$Exact<ButtonPropsType>,
  formApi: InputApiType,
  submitTag?: string
};

const SubmitButtonBase = ({
  formApi,
  submitTag,
  ...rest
}: SubmitButtonBasePropsType) => (
  <Button onClick={() => formApi.submit(submitTag)} {...rest} />
);

SubmitButtonBase.defaultProps = {
  submitTag: undefined
};

const SubmitButton = connectInput(SubmitButtonBase, { bundleApi: true });

export default Button;
export { SubmitButton };
