// imports
import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

// styled divs saved to variables
const Section = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  maxWidth: '1000px',
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: '100px 32px',
  [theme.breakpoints.down('sm')]: {
    padding: '60px 24px',
  },
  [theme.breakpoints.down('xs')]: {
    padding: '60px 16px',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 500,
  marginBottom: '3rem',
  lineHeight: 1.2,
  fontFamily: "Poppins, sans-serif",
  padding: '0 32px',
  [theme.breakpoints.down('md')]: {
    fontSize: '36px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '34px',
    marginBottom: '2.5rem',
  },
  [theme.breakpoints.down('xs')]: {
   fontSize: '32px',
   padding: 0,
  },
}));

const Subtitle = styled(Typography)({
  fontSize: '16px',
  fontWeight: 'bolder',
  margin: '32px 0 5px',
  fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
});

const Info = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  margin: '0 100px',
  fontWeight: 500,
  paddingBottom: '1.5rem',
  fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  [theme.breakpoints.down('sm')]: {
    margin: '0 18px',
  },
  [theme.breakpoints.down('xs')]: {
   margin: '0 12px',
  },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  width: '97.5%',
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));


const Paragraph = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 400,
  marginBottom: '1.5rem',
  padding: '0 16px',
  fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  [theme.breakpoints.down('xs')]: {
   padding: '0 12px'
  },
}));

// component content
const About = () => {
  return (
    <Section id='about'>
      <Title variant="h2" component="h1">
        About Treatogether
      </Title>
      <Info variant="body1">
      Treatogether is an advanced Artificial Intelligence program that helps you decide what kidney stone treatment options to discuss with your doctor.
      </Info>
      <BoxContainer>
        <Paragraph variant="body1">
          <b style={{ fontSize: '2rem', fontFamily: 'Poppins, sans-serif', fontWeight: 500, }}>01.</b> 
          <br></br>
          Treatogether analyzes your particular stone information in tandem with tens of thousands of cases just like yours.
        </Paragraph>
        <Paragraph variant="body1">
          <b style={{ fontSize: '2rem', fontFamily: 'Poppins, sans-serif', fontWeight: 500, }}>02.</b> 
          <br></br>
          With this information we predict the likelihood of success of the main surgical treatment methods tailored to your stone.
        </Paragraph>
        <Paragraph variant="body1">
          <b style={{ fontSize: '2rem', fontFamily: 'Poppins, sans-serif', fontWeight: 500, }}>03.</b> 
          <br></br>
          We'll show you urologists in your area with whom you can further discuss your treatment options.
        </Paragraph>
      </BoxContainer>
      <Subtitle variant="h5" component="h2">
        TELL ME MORE
      </Subtitle>
      <Paragraph variant="body1">
      ‍Our proprietary software is a tool for you to stay informed about your healthcare options. If you have a kidney stone, Treatogether can give you the information to speak to your doctor with confidence. Simply answer a few questions about your health and stone, then Treatogether will give you a recommendation based on tens of thousands of case studies. We'll even help you connect with a urologist in your area so that you may have a clear path to the kidney stone treatment that is right for you.
      </Paragraph>
    </Section>
  );
}

// export
export default About;
