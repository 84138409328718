// imports
import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Typography, Link, Divider, IconButton } from '@material-ui/core';
import Logo from '../../images/TtFooter.png';

// styled divs saved to variables
const Footer = styled('div')({
  textAlign: 'center',
  paddingBottom: '16px',
});

const StyledIconButton = styled(IconButton)({
    width: "100px",
    padding: '0',
    '&:hover': {
      backgroundColor: "transparent",
    },
  });

const LogoImage = styled('img')(({ theme }) => ({
  height: '100px', 
  paddingBottom: '0px',
  [theme.breakpoints.down('lg')]: {
   height: '90px'
  },
  [theme.breakpoints.down('md')]: {
   height: '82px',
  },
  [theme.breakpoints.down('sm')]: {
   height: '75px',
  },
  [theme.breakpoints.down('xs')]: {
   height: "67px",
  },
}));

const StyledLink = styled(Link)({
  display: 'inline-flex',
  padding: '10px 20px',
  fontFamily: "Poppins, sans-serif",
  fontSize: '14px',
  color: '#007bff',
  fontWeight: 300,
  textDecoration: "underline",
});

const StyledDivider = styled(Divider)({
  margin: '10px auto 10px',
  height: '1px',
  width: '90%',
  color: '#0000001F',
  maxWidth: '1000px',
});

const Copyright = styled(Typography)(({ theme }) => ({
  color: '#919197', 
  fontFamily: "Poppins, sans-serif",
  fontSize: '14px',
  fontWeight: 300,
  padding: "12px 0 12px",
  [theme.breakpoints.down('xs')]: {
   padding: '10px 0 10px',
   margin: '0 24px'
  },
}));

// component content
const RegFooter = () => {
  const year = new Date().getFullYear();

  return (
    <Footer>
      <StyledIconButton edge="start" color="inherit" aria-label="logo" href="/">
      <LogoImage src={Logo} alt="Logo" />
      </StyledIconButton>
      <div style={{ display: 'block'}}>
      <StyledLink href="/terms-and-conditions">Terms & Conditions</StyledLink>
      <StyledLink href="/privacy-policy">Privacy Policy</StyledLink>
      </div>
      <StyledDivider />
      <Copyright>&copy; {year} Translational Analytics and Statistics</Copyright>
    </Footer>
  );
}

// export
export default RegFooter;
