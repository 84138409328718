// @flow strict

// Libraries
import * as React from "react";
import { CircularProgress, Typography } from "@material-ui/core";

// Relative Imports
import { Wrapper } from "./styled";

type Props = {
  loadingText: string
};

function LoadingIndicator(props: Props) {
  const { loadingText } = props;

  return (
    <Wrapper>
      <CircularProgress />
      <Typography variant="display1">{loadingText}</Typography>
    </Wrapper>
  );
}

export default LoadingIndicator;
